import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import useScaledImageLoader from "../../Hooks/useScaledImageLoader";

const headingStyles = {
    fontSize: "2.5em",
    fontWeight: 500
}

const contentContainerStyles = (theme: any) => ({
    [theme.breakpoints.down('md')]: {
        mx: "auto"
    },
});

const contentStyles = (theme: any) => ({
    [theme.breakpoints.down('md')]: {
        textAlign: "center"
    },
    my: 5,
    "& a": {
        color: "inherit"
    }
})

const imageStyles = {
    maxWidth: "100%",
    height: "auto"
}

interface ContentWithLinkAndPhotoProps extends PropsWithChildren<any> {
    heading: string,
    link: string
    buttonText: string,
    imageSrc: string,
    textPosition: "left" | "right",
    background: boolean
}

function ContentWithLinkAndPhoto({heading, link, buttonText, imageSrc, textPosition, background, children}: ContentWithLinkAndPhotoProps) {
    const image = useScaledImageLoader(imageSrc);

    const wrapperStyles = {
        backgroundColor: background ? "#e5e9ec" : "none",
        py: 7
    }

    return (
        <Box sx={wrapperStyles}>
            <Container>
                <Grid container direction={textPosition === "left" ? "row" : "row-reverse"} alignItems="center" columnSpacing={4}>
                    <Grid item md={6} sm={12} sx={contentContainerStyles}>
                        <Box sx={contentStyles}>
                            <Typography variant="h2" sx={headingStyles}>{heading}</Typography>
                            {children &&
                                <p>{children}</p>
                            }
                            <Link to={link}>
                                <Button color="inherit" variant="outlined" size="large">{buttonText}</Button>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <img src={image} alt={image} style={imageStyles}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default ContentWithLinkAndPhoto;