import React, {useEffect} from "react";

interface EventActivatorProps {
    event: () => void
}

export const EventActivator = ({event}: EventActivatorProps) => {
    useEffect(() => {
        event();
    }, [event]);

    return (
        <React.Fragment/>
    );
};

export default EventActivator;
