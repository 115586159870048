import PageHeading from "../Components/Common/PageHeading";
import React from "react";
import {Container, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";

const addressPaperContainerStyles = {
    p: 4,
    maxWidth: 500,
    margin: "auto",
    "& h3": {
        m: 0,
        mb: 1
    },
    "& a": {
        color: "black",
        textDecoration: "underline"
    },
    "& address": {
        fontStyle: "normal",
        lineHeight: 2,
    }
}

function ContactInfoPage({topMenuHeight}: {topMenuHeight: number}) {
    return (
        <Container sx={{pt: topMenuHeight + "px"}}>
            <PageHeading>Kontakt</PageHeading>

            <Paper sx={addressPaperContainerStyles}>
                <Typography component="address">
                    <h3>Lenka Hlavničková</h3>
                    Tel.: <a href="tel:+420608002228" style={{textDecoration: "none"}}>608 002 228</a><br/>
                    <a href="mailto:rainbowpictures@email.cz">rainbowpictures@email.cz</a><br/>
                    Instagram: <a target="_blank" rel="noreferrer" href="https://www.instagram.com/rainbowpictures.cz/">rainbowpictures.cz</a><br/>
                    Facebook: <a target="_blank" rel="noreferrer" href="https://www.facebook.com/rainbowpictures.cz">Rainbow pictures</a>
                </Typography>
            </Paper>
        </Container>
    )
}

export default ContactInfoPage;