import axios from "axios";
import {QueryFunctionContext, QueryKey} from "react-query/types/core/types";
import {GroupedImages} from "../Types/groupedImages";
import {Image, ImageWithGalleries} from "../Types/image";
import {ImageToUploadWithProgressCallback} from "../Types/imageToUpload";
import {GalleryId} from "./gallery";

type ImageId = { imageId: number }

export function fetchAllImages(): Promise<ImageWithGalleries[]> {
    return axios.get<ImageWithGalleries[]>("/api/images")
        .then(res => res.data)
}

export function fetchImage({queryKey}: QueryFunctionContext<[QueryKey, ImageId]>) {
    const [, {imageId}] = queryKey;
    return axios.get<Image>(`/api/image/${imageId}`)
        .then(res => res.data)
}

export function fetchAvailableImagesForGallery({queryKey}: QueryFunctionContext<[QueryKey, GalleryId]>): Promise<GroupedImages> {
    const [, {galleryId}] = queryKey;
    return axios.get<GroupedImages>(`/api/images?grouped=true&galleryId=${galleryId}`)
        .then(res => res.data)
}

export function uploadImages(images: ImageToUploadWithProgressCallback[]): Promise<void[]> {
    const uploadPromises = images.map(image => {
        let formData = new FormData();
        formData.append("file", image.file);
        formData.append("watermarkImage", image.watermarkImage.toString());
        formData.append("watermarkThumbnail", image.watermarkThumbnail.toString())

        return axios.post<void>("/api/images", image, {headers: {"Content-Type": "multipart/form-data"}, onUploadProgress: image.onUploadProgress})
            .then(res => res.data)
    })

    return Promise.all(uploadPromises);
}

export function editImage(data: Image): Promise<any> {
    return axios.put<void>(`/api/image/${data.id}`, data)
}

export function deleteImage(imageId: number): Promise<void> {
    return axios.delete<void>(`/api/image/${imageId}`)
        .then(res => res.data)
}