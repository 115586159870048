import PageHeading from "../Components/Common/PageHeading";
import PageParagraph from "../Components/Common/PageParagraph";
import React from "react";
import {Container, Table, TableBody, TableContainer, TableFooter} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";


function PriceListPage({topMenuHeight}: {topMenuHeight: number}) {
    return (
        <Container sx={{pt: topMenuHeight + "px"}}>
            <PageHeading>Ceník</PageHeading>

            <PageParagraph>Cena za balíček fotografií je splatná v den focení. Přijímám buďto hotovost přímo na místě nebo zasílám platební údaje na email. Bez zaplacení upravené fotografie nezasílám.</PageParagraph>
            <PageParagraph><strong>Neupravené snímky neposkytuji</strong>, po focení obdržíte odkaz na náhledy všech zdařilých fotografií přes webový portál nahledovky.cz, kde uskutečníte svůj výběr a závazně jej potvrdíte. Tyto náhledy není povoleno stahovat a šířit. Úprava fotografie je součástí mé práce, neupravené snímky nejsou považovány za hotové dílo.</PageParagraph>
            <PageParagraph>Upravené fotografie očekávejte přibližně 8-10 pracovních dní ode dne odeslání vašeho výběru.</PageParagraph>
            <PageParagraph>Pokud se předem nedohodneme jinak, všechny pořízené fotografie mohu využít pro svou prezentaci na webu, facebooku a instagramu.</PageParagraph>
            <PageParagraph>Fotím v&nbsp;exteriéru, případně po domluvě u vás doma či na jiném veřejně přístupném místě.</PageParagraph>
            <PageParagraph>Pro lokality mimo znojemský a brněnský okres se k&nbsp;cenám připočítává cestovné, přesnou cenu cesty dohodneme individuálně.</PageParagraph>
            <PageParagraph>Vybrané fotografie poskytuji pouze v&nbsp;elektronické podobě.</PageParagraph>
            <PageParagraph><strong>Čas focení není striktně určen</strong>, bude se fotit tak dlouho, dokud to bude dávat smysl. Orientační doba focení je 30-90 minut, ale vždy beru ohledy na aktuální podmínky. Focení novorozenců je delší (cca 2 hodiny) kvůli uspávání, kojení atd. Oproti tomu focení s dětmi je převážně kratší (30-40 minut), než se děti začnou nudit.</PageParagraph>
            
            <TableContainer component={Paper} sx={{mt: 4}}>
                <Table>
                    <TableHead sx={{backgroundColor: "#eee"}}>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Upravené fotografie</TableCell>
                            <TableCell align="right">Cena</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Portrét</TableCell>
                            <TableCell align="right">10</TableCell>
                            <TableCell align="right">1200&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Párové focení</TableCell>
                            <TableCell align="right">10</TableCell>
                            <TableCell align="right">1300&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rodinné focení</TableCell>
                            <TableCell align="right">15</TableCell>
                            <TableCell align="right">1500&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Těhotenské focení</TableCell>
                            <TableCell align="right">10</TableCell>
                            <TableCell align="right">1300&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Newborn</TableCell>
                            <TableCell align="right">15</TableCell>
                            <TableCell align="right">1800&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Minifocení (pouze Šanov)</TableCell>
                            <TableCell align="right">5</TableCell>
                            <TableCell align="right">700&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Focení akcí či oslav</TableCell>
                            <TableCell align="right">dle domluvy</TableCell>
                            <TableCell align="right">od 2000&nbsp;Kč</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}>1ks fotografie nad rámec balíčku</TableCell>
                            <TableCell align="right">100&nbsp;Kč</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Příplatek za retuš nad rámec běžné úpravy (např. úprava proporcí postavy, zeštíhlení, odstranění podbradku, výměna obličejů na fotografii, &#8230;)</TableCell>
                            <TableCell align="right">50&nbsp;Kč</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </Container>
    )
}

export default PriceListPage;