import {Image} from "../../../Common/Types/image";
import React, {useEffect, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Typography} from "@mui/material";
import UnorderedList from "./UnorderedList/UnorderedList";
import OrderedList from "./OrderedList/OrderedList";
import {OrderedImage} from "../../../Common/Types/orderedImage";
import update from "immutability-helper";
import MainPhoto from "./MainPhoto/MainPhoto";

interface GalleryManagerProps {
    initialMainPhoto?: Image
    initialOrderedPhotos: OrderedImage[],
    initialUnorderedPhotos: Image[],
    onChange: (mainPhoto: Image | undefined, orderedPhotos: OrderedImage[], unorderedImages: Image[]) => void
}

function GalleryManager({initialMainPhoto, initialOrderedPhotos, initialUnorderedPhotos, onChange}: GalleryManagerProps) {
    const [mainPhoto, setMainPhoto] = useState<Image | undefined>(initialMainPhoto);
    const [orderedPhotos, setOrderedPhotos] = useState<OrderedImage[]>(initialOrderedPhotos);
    const [unorderedPhotos, setUnorderedPhotos] = useState<Image[]>(initialUnorderedPhotos);

    useEffect(() => {
        onChange(mainPhoto, orderedPhotos, unorderedPhotos);
    }, [mainPhoto, orderedPhotos, unorderedPhotos]);

    const removeMainPhoto = (): Image | undefined => {
        const photo = mainPhoto;
        setMainPhoto(undefined);
        return photo;
    }

    const addMainPhoto = (image: Image) => {
        setMainPhoto(image);
    }

    const removeOrderedPhoto = (index: number): OrderedImage => {
        const removedPhoto = orderedPhotos[index];
        setOrderedPhotos((prevOrderedPhotos: OrderedImage[]) => {
            const updated = update(prevOrderedPhotos, {
                $splice: [
                    [index, 1]
                ]
            })
            updated.forEach((image, index) => image.sequence = index);
            return updated;
        });
        return removedPhoto;
    }

    const addOrderedPhoto = (index: number, image: Image) => {
        setOrderedPhotos((prevOrderedPhotos: OrderedImage[]) => {
            const updated = update(prevOrderedPhotos, {
                $splice: [
                    [index, 0, {...image, sequence: 999}]
                ]
            });
            updated.forEach((image, index) => image.sequence = index);
            return updated;
        })
    }

    const moveOrderedPhoto = (fromIndex: number, toIndex: number) => {
        setOrderedPhotos((prevOrderedPhotos: OrderedImage[]) => {
            const updated = update(prevOrderedPhotos, {
                $splice: [
                    [fromIndex, 1],
                    [toIndex, 0, prevOrderedPhotos[fromIndex] as OrderedImage]
                ]
            });
            updated.forEach((image, index) => image.sequence = index);
            return updated;
        })
    }

    const removeUnorderedPhoto = (index: number): Image => {
        const removedPhoto = unorderedPhotos[index];
        setUnorderedPhotos((prevUnorderedPhotos: Image[]) =>
            update(prevUnorderedPhotos, {
                $splice: [
                    [index, 1]
                ]
            })
        )
        return removedPhoto;
    }

    const addUnorderedPhoto = (image: Image) => {
        setUnorderedPhotos((prevUnorderedPhotos:Image[]) => {
            const updated = update(prevUnorderedPhotos, {
                $push: [image]
            })
            updated.sort((a, b) => a.id - b.id);
            return updated;
        });
    }

    const selectMainPhoto = (imageId: number) => {
        if (mainPhoto?.id === imageId) {
            // If main photo is dragged and dropped back, don't do anything
            return;
        }

        const originalMainPhoto = removeMainPhoto();

        const orderedPhotoIndex = orderedPhotos.findIndex(photo => photo.id === imageId);
        if (orderedPhotoIndex !== -1) {
            const targetPhoto = removeOrderedPhoto(orderedPhotoIndex);
            addMainPhoto(targetPhoto);
        }

        const unorderedPhotoIndex = unorderedPhotos.findIndex(photo => photo.id === imageId);
        if (unorderedPhotoIndex !== -1) {
            const targetPhoto = removeUnorderedPhoto(unorderedPhotoIndex);
            addMainPhoto(targetPhoto);
        }

        if (originalMainPhoto) {
            addOrderedPhoto(0, originalMainPhoto);
        }
    }

    const moveToOrdered = (imageId: number, position: number) => {
        // Move main photo to ordered photos
        if (mainPhoto?.id === imageId) {
            const targetPhoto = removeMainPhoto();
            if (targetPhoto) {
                addOrderedPhoto(position, targetPhoto)
            }
        }

        // Move ordered photo to ordered photos
        const orderedPhotoIndex = orderedPhotos.findIndex(photo => photo.id === imageId);
        if (orderedPhotoIndex !== -1) {
            moveOrderedPhoto(orderedPhotoIndex, position);
        }

        // Move unordered photo to ordered photos
        const unorderedPhotoIndex = unorderedPhotos.findIndex(photo => photo.id === imageId);
        if (unorderedPhotoIndex !== -1) {
            const targetPhoto = removeUnorderedPhoto(unorderedPhotoIndex);
            addOrderedPhoto(position, targetPhoto);
        }
    }

    const moveToUnordered = (imageId: number) => {
        // Move main photo to unordered photos
        if (mainPhoto?.id === imageId) {
            const targetPhoto = removeMainPhoto();
            if (targetPhoto) {
                addUnorderedPhoto(targetPhoto);
            }
        }

        // Move ordered photo to unordered photos
        const orderedPhotoIndex = orderedPhotos.findIndex(photo => photo.id === imageId);
        if (orderedPhotoIndex !== -1) {
            const targetPhoto = removeOrderedPhoto(orderedPhotoIndex);
            addUnorderedPhoto(targetPhoto);
        }
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Typography variant="caption">Hlavní fotka</Typography>
            <MainPhoto image={mainPhoto} selectMainPhoto={selectMainPhoto}/>

            <Typography variant="caption">Seřazené na začátku</Typography>
            <OrderedList images={orderedPhotos} moveToOrdered={moveToOrdered}/>

            <Typography variant="caption">Ostatní</Typography>
            <UnorderedList images={unorderedPhotos} moveToUnordered={moveToUnordered}/>
        </DndProvider>
    )
}

export default GalleryManager;