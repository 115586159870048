import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Box, ImageListItem, ImageListItemBar, Typography} from "@mui/material";
import {OrderedImage} from "../../../../Common/Types/orderedImage";
import NoWatermarkWarningSign from "../../Common/NoWatermarkWarningSign";

interface OrderedListImageProps {
    image: OrderedImage,
    moveToOrdered: (imageId: number, position: number) => void
}

function OrderedListImage({image, moveToOrdered}: OrderedListImageProps) {
    const ref = useRef<any>(null)

    const [, drop] = useDrop<OrderedImage, void>({
        accept: "image",
        drop: (item) => {
            if (!ref.current) {
                return;
            }

            moveToOrdered(item.id, image.sequence || 0);
        }
    });

    const [{opacity}, drag] = useDrag(
        () => ({
            type: "image",
            item: {id: image.id},
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }),
        []
    )

    drag(drop(ref))

    return (
        <ImageListItem ref={ref} key={image.id} sx={{opacity}}>
            <Box sx={{
                backgroundImage: `url(/api/image/${image.id}/serve?thumbnail=true)`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: 250,
                p: 1
            }}>
                {!image.watermarkImage &&
                    <Box sx={{position: "absolute", zIndex: 2}}>
                        <NoWatermarkWarningSign/>
                    </Box>
                }
            </Box>

            <ImageListItemBar
                sx={{
                    background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                actionIcon={<Typography variant="h4" sx={{color: "white", px: 2, py: 1}}>{(image.sequence || 0) + 1}</Typography>}
                position="top"/>
        </ImageListItem>
    );
}

export default OrderedListImage;