import {Box, Container, Grid, Theme, Typography} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import PageHeading from "../Components/Common/PageHeading";
import {useQuery} from "react-query";
import {galleriesKey} from "../../Common/Api/queryKeys";
import {fetchAllGalleries} from "../../Common/Api/gallery";
import LoadingArea from "../../Admin/Components/Common/LoadingArea";
import Alert from "@mui/material/Alert";
import {Link} from "react-router-dom";

interface PortfolioPageProps {
    topMenuHeight: number
}

function PortfolioPage({topMenuHeight}: PortfolioPageProps) {
    const {
        data: galleries,
        isFetching,
        isError,
    } = useQuery(galleriesKey(), fetchAllGalleries);

    const containerStyles: SxProps<Theme> = {
        paddingTop: topMenuHeight + "px",
        backgroundColor: "white",
    }

    const gridInnerStyle: SxProps<Theme> = {
        textAlign: "center"
    }

    const imageContainerStyle: SxProps<Theme> = {
        overflow: "hidden",
        height: 400,
        cursor: "pointer",
        borderRadius: 3,
    }

    const imageStyle: SxProps<Theme> = {
        backgroundPosition: "center center",
        backgroundSize: "cover",
        transition: "padding 0.7s ease-in-out, margin 0.7s ease-in-out, filter 0.2s ease-in-out",
        width: "100%",
        height: "100%",
        padding: 0,
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
        "&:hover": {
            padding: 2,
            margin: -2
        }
    }

    const textContainerStyle: SxProps<Theme> = {
        py: 2,
        px: 6,
        mx: "auto",
        textAlign: "center",
        border: "0.5px solid rgb(150, 150, 150)",
        borderRadius: 2,
        display: "inline-block",
        position: "relative",
        top: -30,
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        backdropFilter: "blur(8px)",
        maxWidth: "50%"
    }

    const textStyle: SxProps<Theme> = {
        color: "black",
        fontSize: "1.5em",
        fontWeight: 300,
    }

    return (
        <Container sx={containerStyles}>
            <PageHeading>Nabídka focení</PageHeading>

            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity="error">Chyba při načítání galerií</Alert> }
            {!isFetching && !isError && galleries &&
                <Grid container spacing={2} sx={{mt: 6}}>
                    {galleries.filter(gallery => gallery.showInPortfolio && gallery.galleryImages.length > 0).map(gallery =>
                        <Grid item key={gallery.id} md={6} xs={12}>
                            <Link to={`/nabidka-foceni/${gallery.id}`}>
                                <Box sx={gridInnerStyle}>
                                    <Box sx={imageContainerStyle}>
                                        <Box sx={{
                                            ...imageStyle,
                                            backgroundImage: "url(/api/image/" + gallery.galleryImages[0].image.id + "/serve?thumbnail=true)",
                                        }}/>
                                    </Box>
                                    <Box sx={textContainerStyle}>
                                        <Typography variant="body1" sx={textStyle}>{gallery.name}</Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }
        </Container>
    )
}

export default PortfolioPage