import {GalleryImage} from "../../../../Common/Types/galleryImage";
import React from "react";
import {ImageList} from "@mui/material";
import ImageSelectorSelectedGroupImage from "./ImageSelectorSelectedGroupImage";
import ImageListPlaceholder from "../../Common/ImageListPlaceholder";

interface ImageSelectorSelectedGroupProps {
    photos: GalleryImage[],
    hoverLabel: string,
    onImageClick: (imageId: number) => void
}

function ImageSelectorSelectedGroup({photos, hoverLabel, onImageClick}: ImageSelectorSelectedGroupProps) {
    return (
        <ImageList cols={5} rowHeight={150}>
            {photos.length === 0 &&
                <ImageListPlaceholder>
                    Žádné obrázky
                </ImageListPlaceholder>
            }
            {photos.map(galleryImage => (
                <ImageSelectorSelectedGroupImage
                    key={galleryImage.image.id}
                    galleryImage={galleryImage}
                    hoverLabel={hoverLabel}
                    onClick={onImageClick}
                />
            ))}
        </ImageList>
    );
}

export default ImageSelectorSelectedGroup