import PageHeading from "../Components/Common/PageHeading";
import PageParagraph from "../Components/Common/PageParagraph";
import React, {CSSProperties} from "react";
import {Box, Container, Grid, Theme, useMediaQuery, useTheme} from "@mui/material";
import useScaledImageLoader from "../Hooks/useScaledImageLoader";
import {SxProps} from "@mui/system";

const imageContainerStyles: SxProps<Theme> = (theme: any) => ({
    [theme.breakpoints.only('sm')]: {
        px: "25%"
    },
    [theme.breakpoints.only('xs')]: {
        px: "10%"
    },
})

const imageStyles: CSSProperties = {
    width: "100%"
}

function AboutMePage({topMenuHeight}: {topMenuHeight: number}) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    const image = useScaledImageLoader("about-me-photo.jpg")

    return (
        <Container sx={{pt: topMenuHeight + "px"}}>
            <PageHeading>O mně</PageHeading>

            <Grid container spacing={isSmall ? 2 : 10}>
                <Grid item md={8} xs={12}>
                    <PageParagraph>Mé jméno je Lenka Hlavničková a jsem začínající fotografkou na jižní Moravě. V současnosti bydlím v malé obci Šanov v okrese Znojmo, ale kus mého srdce zůstal v Brně, kde jsem 9 let žila a studovala, proto ráda jezdím fotit také tam. Kromě toho, že fotím, jsem mámou dvou úžasných chlapečků, se kterými jsem momentálně na rodičovské dovolené.</PageParagraph>
                    <PageParagraph>Miluji fotografie plné života a akce, jsem fanoušek tzv. “momentek”, které co nejlépe odráží náladu našeho setkání a ukazují pravé emoce. Snažím se v průběhu focení navodit co nejpříjemnější atmosféru a jen výjimečně vás formuji do strojených póz. Velmi ráda na fotografie doplňuji efekty, které vytváří kouzelný dojem snímků. Poradím si i s živějšími dětmi, sama vím, jak těžké je někdy zachytit děti, které neposedí nebo nemají náladu, i na to mám pár triků v rukávu. &#128578;</PageParagraph>
                    <PageParagraph>V&nbsp;současnosti nevlastním ateliér, fotím pouze v&nbsp;exteriéru, po dohodě u vás doma či na jiném volně přístupném místě. Po domluvě mohu přijet téměř kamkoli po jihomoravském kraji, do budoucna v&nbsp;případě svateb již po celé republice.</PageParagraph>
                    <PageParagraph>Pokud vás má tvorba zaujala, napište. Ráda vám vytvořím fotografie podle představ. Pokud žádnou představu nemáte, nevadí, můžeme si společně pro začátek jen popovídat o tom, jaký styl by se k&nbsp;vám hodil a časem jistě objevíme to pravé. Vždy však respektuji a oceňuji jakékoli vaše vlastní nápady či inspirace.</PageParagraph>
                    <PageParagraph>Již teď se na setkání s&nbsp;vámi těším! &#128578;</PageParagraph>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={imageContainerStyles}>
                        <img src={image} alt={image} style={imageStyles} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AboutMePage;