import {AppBar, Box, Button, Container, IconButton, Theme, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {ADMINISTRATION_AVAILABLE} from "../../../Common/Storage/localStorageKeys";
import {SxProps} from "@mui/system";

interface TopMenuProps {
    isMainPage: boolean,
    onHeightChange: (height: number) => void
}

function TopMenu({isMainPage, onHeightChange}: TopMenuProps) {
    const theme = useTheme();
    const shrinkMenu = useMediaQuery(theme.breakpoints.down('md'));

    const [onTopOfPage, setOnTopOfPage] = useState(true);
    const [menuOpened, setMenuOpened] = useState(false);

    const menuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
       if (menuRef && menuRef.current) {
           const resizeObserver = new ResizeObserver(() => {
               if (menuRef.current) {
                   onHeightChange(menuRef.current.getBoundingClientRect().height)
               }
           });
           resizeObserver.observe(menuRef.current)
       }
    }, []);

    const topMenuStyle = {
        left: 0,
        right: 0,
        margin: "auto",

        backgroundColor: isMainPage && onTopOfPage ? "rgba(0,0,0,0.2)" : isMainPage ? "rgba(0,0,0,0.7)" : "black",
        transition: "background-color .3s linear",
        boxShadow: "none"
    }

    const brandIconStyle: SxProps<Theme> = {
        lineHeight: "120%",
        fontVariant: "small-caps",
        fontWeight: "bold",
        opacity: .5,
        "& a": {
            color: "inherit",
            textDecoration: "inherit",
        }
    }

    const topMenuButtonContainer = {
        maxHeight: shrinkMenu && !menuOpened ? 0 : 500,
        width: shrinkMenu ? "100%" : "auto",
        transition: "max-height .5s ease",
        overflow: "hidden",
        "& a": {
            color: "inherit",
            textDecoration: "none"
        }
    }

    const topMenuButtonStyle = {
        display: shrinkMenu ? "block" : "inline-block",
        margin: shrinkMenu ? "auto" : 0,
        color: "inherit",
        fontSize: "1.2em",
        textTransform: "none",
        padding: ".3em 1em",
        fontWeight: 300,
        "&:hover": {
            backgroundColor: isMainPage && onTopOfPage ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.3)"
        }
    }

    function toggleOpenMenu() {
        setMenuOpened(!menuOpened);
    }

    function onScroll() {
        setOnTopOfPage(window.scrollY === 0);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    return (
        <AppBar ref={menuRef} sx={topMenuStyle} position="fixed">
            <Container>
                <Toolbar>
                    <Box sx={brandIconStyle}>
                        <Link to="/">
                            Rainbow<br/>
                            Pictures
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 1}}/>

                    <Box sx={topMenuButtonContainer}>
                        <Link to="/" onClick={() => setMenuOpened(false)}><Button sx={topMenuButtonStyle}>Úvod</Button></Link>
                        <Link to="/nabidka-foceni" onClick={() => setMenuOpened(false)}><Button sx={topMenuButtonStyle}>Nabídka focení</Button></Link>
                        <Link to="/cenik" onClick={() => setMenuOpened(false)}><Button sx={topMenuButtonStyle}>Ceník</Button></Link>
                        <Link to="/o-mne" onClick={() => setMenuOpened(false)}><Button sx={topMenuButtonStyle}>O mně</Button></Link>
                        <Link to="/podminky" onClick={() => setMenuOpened(false)}><Button sx={topMenuButtonStyle}>Podmínky</Button></Link>
                        <Link to="/kontakt" onClick={() => setMenuOpened(false)}><Button sx={topMenuButtonStyle}>Kontakt</Button></Link>
                        {localStorage.getItem(ADMINISTRATION_AVAILABLE) &&
                            <Link to="/admin"><Button sx={{...topMenuButtonStyle, color: "silver"}}>Administrace</Button></Link>
                        }
                    </Box>
                    <Box display={shrinkMenu ? "block" : "none"}>
                        <IconButton onClick={toggleOpenMenu} size="large" edge="start" color="inherit" aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default TopMenu;