import React from "react";
import {Typography} from "@mui/material";

interface PageHeadingProps {
    children: React.ReactNode
}

function PageHeading({children}: PageHeadingProps) {
    return (
        <Typography variant="h2" sx={{mt: 6, mb: 3}}>{children}</Typography>
    )
}

export default PageHeading;