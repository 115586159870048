import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {deleteImage} from "../../../Common/Api/image";

interface DeletePhotoDialogProps {
    activeId: number
    open: boolean,
    onClose: (saved: boolean) => void,
}

function DeletePhotoDialog({activeId, open, onClose}: DeletePhotoDialogProps) {
    const {
        mutate: deleteGallery,
        isLoading
    } = useMutation<void, AxiosError<any>, number, any>(deleteImage, {
        onSuccess: () => {
            onClose(true)
        }
    });

    const handleDeletePhoto = () => {
        deleteGallery(activeId)
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>Skutečně smazat tento obrázek?</DialogTitle>
            <DialogContent sx={{textAlign: "center"}}>
                <img
                    src={`/api/image/${activeId}/serve?thumbnail=true`}
                    alt={activeId?.toString(10)}
                    style={{maxWidth: "100%", maxHeight: "100%"}}
                    loading="lazy"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)} disabled={isLoading}>Zrušit</Button>
                <Button variant="contained" onClick={handleDeletePhoto} disabled={isLoading} autoFocus>Smazat</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeletePhotoDialog