import React, {CSSProperties, useEffect} from "react";
// @ts-ignore
import PhotoSwipeLightbox from "photoswipe/lightbox";

import "photoswipe/style.css";
import {Box, Grid, Theme} from "@mui/material";
import {SxProps} from "@mui/system";

export interface Image {
    imageUrl: string,
    imageWidth: number,
    imageHeight: number,
    thumbnailUrl: string,
    caption?: string
}

interface ImageGalleryProps {
    id: string,
    images: Image[]
}

const imageContainerStyle: SxProps<Theme> = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

const imageStyle: CSSProperties = {
    maxWidth: "100%",
    maxHeight: 300,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
}

function ImageGallery({id, images}: ImageGalleryProps) {
    useEffect(() => {
        let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
            gallery: '#' + id,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });

        if (lightbox != null) {
            lightbox.init();
        }

        return () => {
            if (lightbox != null) {
                lightbox.destroy();
                lightbox = null;
            }
        };
    }, []);

    return (
        <div className="pswp-gallery" id={id}>
            <Grid container spacing={1} sx={{mt: 6}}>
                {images.map((image, index) =>
                    <Grid item key={id + "-" + index} sm={4} xs={6}>
                        <Box sx={imageContainerStyle}>
                            <a href={image.imageUrl}
                               data-pswp-width={image.imageWidth}
                               data-pswp-height={image.imageHeight}
                               target="_blank"
                               rel="noreferrer">
                                <img src={image.thumbnailUrl} alt={image.caption} style={imageStyle}/>
                            </a>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export default ImageGallery;