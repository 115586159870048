import {useDrop} from "react-dnd";
import {OrderedImage} from "../../../../Common/Types/orderedImage";
import {Identifier} from "dnd-core";
import ImageListPlaceholder from "../../Common/ImageListPlaceholder";
import React from "react";

interface OrderedListImagePlaceholderProps {
    position: number,
    moveToOrdered: (imageId: number, position: number) => void,
    label: "empty" | "nonempty"
}

function OrderedListImagePlaceholder({position, moveToOrdered, label}: OrderedListImagePlaceholderProps) {
    const [, dropRef] = useDrop<OrderedImage, void, { handlerId: Identifier | null }>({
        accept: "image",
        drop: (item) => {
            moveToOrdered(item.id, position);
        }
    });

    return (
        <ImageListPlaceholder ref={dropRef}>
            {label === "empty" && "Prázdné"}
            {label === "nonempty" && "Přidat na konec"}
        </ImageListPlaceholder>
    )
}

export default OrderedListImagePlaceholder;