import {useNavigate} from "react-router-dom";
import {Box, Button, Theme} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import {SxProps} from "@mui/system";
import {To} from "@remix-run/router";

interface PageBackLinkProps {
    to: To,
    sx?: SxProps<Theme>
}

const containerStyles: SxProps<Theme> = {
    mb: 3
}

function PageBackLink({to, sx}: PageBackLinkProps) {
    const navigate = useNavigate();

    return (
        <Box sx={Object.assign({}, containerStyles, sx)}>
            <Button onClick={() => navigate(to)} startIcon={<ArrowBackIosIcon/>} size="large">Zpět na nabídku</Button>
        </Box>
    )
}

export default PageBackLink;