import {Box, ImageListItem, Typography} from "@mui/material";
import React, {ForwardedRef} from "react";

interface ImageListPlaceholderProps {
    children: React.ReactNode
}

function ImageListPlaceholder({children}: ImageListPlaceholderProps, ref: ForwardedRef<any>) {
    return (
        <ImageListItem ref={ref}>
            <Box sx={{
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                border: "2px dashed silver",
                color: "silver",
                borderRadius: 3}}
            >
                <Typography variant="body1">
                    {children}
                </Typography>
            </Box>
        </ImageListItem>
    )
}

export default React.forwardRef(ImageListPlaceholder)