import React from "react";
import {Dialog, DialogTitle} from "@mui/material";
import {AxiosError} from "axios";
import {useMutation} from "react-query";
import {Gallery} from "../../../Common/Types/gallery";
import {createGallery} from "../../../Common/Api/gallery";
import EditGalleryForm, {EditGalleryFormValues, nameToId} from "../Form/EditGalleryForm";

interface NewGalleryDialogProps {
    open: boolean,
    onClose: (saved: boolean) => void
}

function NewGalleryDialog({open, onClose = () => {}}: NewGalleryDialogProps) {

    const mutation = useMutation<Gallery, AxiosError<any>, Gallery, any>(createGallery, {
        onSuccess: () => {
            onClose(true)
        }
    });

    const handleSubmit = ({id, name, description, showInPortfolio, customId}: EditGalleryFormValues) => {
        mutation.mutate({
            id: customId ? id : nameToId(name),
            name: name,
            description: description,
            showInPortfolio: showInPortfolio,
            galleryImages: []
        })
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Nová galerie</DialogTitle>
            <EditGalleryForm
                initialValues={{
                    id: "",
                    name: "",
                    description: "",
                    showInPortfolio: true,
                    customId: false
                }}
                onSubmit={handleSubmit}
                onDialogClose={onClose}/>
        </Dialog>
    );
}

export default NewGalleryDialog;