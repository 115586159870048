import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import AdminPage from "./Admin/Pages/AdminPage";
import HomePage from "./Home/Pages/HomePage";
import {useMatomo} from "@mitresthen/matomo-tracker-react";

function RouteDefinition() {
    const location = useLocation();
    const {trackPageView} = useMatomo();

    useEffect(() => {
        trackPageView();
    }, [location])

    return (
        <Routes>
            <Route path="/admin/*" element={<AdminPage/>} />
            <Route path="/*" element={<HomePage/>} />
        </Routes>
    )
}

function App() {
    return (
        <BrowserRouter>
            <RouteDefinition/>
        </BrowserRouter>
    );
}

export default App;
