export const galleriesKey= () =>
    "galleries"
export const galleryKey = (galleryId: string): [string, {galleryId: string}] =>
    ["gallery", {galleryId}]
export const galleryExistsKey = (galleryId: string): [string, {galleryId: string}] =>
    ["galleryExists", {galleryId}]

export const imageKey = (imageId: number): [string, {imageId: number}] =>
    ["image", {imageId}]

export const imagesKey = () =>
    "images"

export const availableImagesForGalleryKey = (galleryId: string): [string, {galleryId: string}] =>
    ["availableImagesForGallery", {galleryId}]

export const userInfoKey = () =>
    "userInfo"