import React, {useState} from "react";
import {Image} from "../../../../Common/Types/image";
import {useDrop} from "react-dnd";
import {ImageList} from "@mui/material";
import UnorderedListImage from "./UnorderedListImage";
import UnorderedListPlaceholder from "./UnorderedListPlaceholder";

interface UnorderedListProps {
    images: Image[],
    moveToUnordered: (imageId: number) => void
}

function UnorderedList({images, moveToUnordered}: UnorderedListProps) {
    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(images.length === 0);

    const [, dropRef] = useDrop<Image, void>({
        accept: "image",
        drop: (item) => {
            moveToUnordered(item.id);
        },
        collect: (monitor) => {
            setShowPlaceholder(monitor.isOver() || images.length === 0);
        }
    });

    return (
        <ImageList ref={dropRef} cols={5} rowHeight={150}>
            {images.map(image => (
                <UnorderedListImage key={image.id} image={image}/>
            ))}
            {showPlaceholder &&
                <UnorderedListPlaceholder label={images.length === 0 ? "empty" : "nonempty"}/>
            }
        </ImageList>
    )
}

export default UnorderedList;