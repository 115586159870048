import {Box, ImageListItem} from "@mui/material";
import React from "react";
import {Image} from "../../../../Common/Types/image";
import NoWatermarkWarningSign from "../../Common/NoWatermarkWarningSign";

interface ImageSelectorGroupImageProps {
    image: Image,
    onClick: (imageId: number) => void
}

function ImageSelectorGroupImage({image, onClick}: ImageSelectorGroupImageProps) {
    return (
        <ImageListItem onClick={() => onClick(image.id)}>
            <Box sx={{
                backgroundImage: `url(/api/image/${image.id}/serve?thumbnail=true)`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: 150,
                cursor: "pointer",
                "&:hover *": {
                    display: "flex"
                }
            }}>
                <Box sx={{position: "absolute", p: 1}}>
                    {!image.watermarkImage &&
                        <NoWatermarkWarningSign/>
                    }
                </Box>
                <Box sx={{
                    display: "none",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "white",
                    textShadow: "0px 0px 10px black",
                }}>
                    Vybrat
                </Box>
            </Box>
        </ImageListItem>
    )
}

export default ImageSelectorGroupImage