import {Route, Routes, useMatch} from "react-router-dom";
import React, {useState} from "react";
import MainPage from "./MainPage";
import TopMenu from "../Components/TopMenu/TopMenu";
import PortfolioPage from "./PortfolioPage";
import PageFooter from "../Components/ContentParts/PageFooter";
import GalleryPage from "./GalleryPage";
import {Box} from "@mui/material";
import AboutMePage from "./AboutMePage";
import PriceListPage from "./PriceListPage";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import ContactInfoPage from "./ContactInfoPage";
import ScrollToTop from "../../Common/Components/ScrollToTop";

function HomePage() {
    const routeMatch = useMatch("/");
    const [topMenuHeight, setTopMenuHeight] = useState<number>(0)

    return (
        <React.Fragment>
            <ScrollToTop/>

            <TopMenu onHeightChange={height => setTopMenuHeight(height)} isMainPage={!!routeMatch}/>

            <Box sx={{minHeight: "100vh"}}>
                <Routes>
                    <Route path="/" element={<MainPage topMenuHeight={topMenuHeight}/>}/>
                    <Route path="/nabidka-foceni" element={<PortfolioPage topMenuHeight={topMenuHeight}/>}/>
                    <Route path="/nabidka-foceni/:galleryId" element={<GalleryPage topMenuHeight={topMenuHeight}/>}/>
                    <Route path="/cenik" element={<PriceListPage topMenuHeight={topMenuHeight}/>}/>
                    <Route path="/o-mne" element={<AboutMePage topMenuHeight={topMenuHeight}/>}/>
                    <Route path="/podminky" element={<TermsAndConditionsPage topMenuHeight={topMenuHeight}/>}/>
                    <Route path="/kontakt" element={<ContactInfoPage topMenuHeight={topMenuHeight}/>}/>
                </Routes>
            </Box>

            <PageFooter/>
        </React.Fragment>
    )
}

export default HomePage;