import {ImageList} from "@mui/material";
import React from "react";
import {Image} from "../../../../Common/Types/image";
import ImageSelectorGroupImage from "./ImageSelectorGroupImage";
import ImageListPlaceholder from "../../Common/ImageListPlaceholder";

interface ImageSelectorGroupProps {
    photos: Image[],
    onImageClick?: (imageId: number) => void
}

function ImageSelectorGroup({photos, onImageClick}: ImageSelectorGroupProps) {
    return (
        <ImageList cols={5} rowHeight={150}>
            {photos.length === 0 &&
                <ImageListPlaceholder>
                    Žádné obrázky
                </ImageListPlaceholder>
            }
            {photos.map(image => (
                <ImageSelectorGroupImage
                    key={image.id}
                    image={image}
                    onClick={onImageClick ? onImageClick : () => {}}
                />
            ))}
        </ImageList>
    )
}

export default ImageSelectorGroup;