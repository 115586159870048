import PageHeading from "../Components/Common/PageHeading";
import PageParagraph from "../Components/Common/PageParagraph";
import React from "react";
import {Container} from "@mui/material";


function TermsAndConditionsPage({topMenuHeight}: {topMenuHeight: number}) {
    return (
        <Container sx={{pt: topMenuHeight + "px"}}>
            <PageHeading>Podmínky</PageHeading>
            <PageParagraph>V případě, že z Vaší strany nebude možné uskutečnit focení v předem domluvený termín, prosím, informujte mě o této skutečnosti co nejdříve, ideálně alespoň den předem.</PageParagraph>
            <PageParagraph>Neupravené snímky neposkytuji, po focení obdržíte odkaz na náhledy všech zdařilých fotografií přes webový portál nahledovky.cz, kde uskutečníte svůj výběr a závazně jej potvrdíte. Tyto náhledy není povoleno stahovat a šířit, úprava fotografie je součástí práce.</PageParagraph>
            <PageParagraph>Prosím, při sdílení obdržených upravených fotografií, nepoužívejte již žádné filtry ani jiné formy úpravy. Fotografie upravuji tak, aby byly k Vaší spokojenosti, proto pokud vám zaslaná úprava nevyhovuje, dejte mi raději vědět a společně můžeme dohodnout ještě následnou korekci úpravy tak, aby se vám snímky skutečně líbily, pokud to bude možné. Upravuji na monitoru s vykalibrovanými barvami, jakékoli další filtry celou práci znehodnocují.</PageParagraph>
            <PageParagraph>Pokud jsme se předem nedohodli jinak, všechny pořízené fotografie mohu použít k mé prezentaci na webových stránkách, facebooku a instagramu.</PageParagraph>
            <PageParagraph>Cena za balíček fotografií je splatná v den focení, přijímám buďto hotovost přímo na místě nebo Vám zašlu platební údaje na email. Bez zaplacení upravené fotografie nezasílám.</PageParagraph>
            <PageParagraph>Děkuji za pochopení a respektování výše uvedených podmínek spolupráce, využitím mých služeb s těmito podmínkami souhlasíte.</PageParagraph>
        </Container>
    )
}

export default TermsAndConditionsPage;