import {FieldProps} from "formik/dist/Field";
import {GalleryImage} from "../../../Common/Types/galleryImage";
import GalleryManager from "../GalleryManager/GalleryManager";
import {Image} from "../../../Common/Types/image";
import {OrderedImage} from "../../../Common/Types/orderedImage";

interface GalleryManagerFieldProps extends FieldProps<GalleryImage[]> {
}

function GalleryManagerField({field, form: {setFieldValue}}: GalleryManagerFieldProps) {
    const getInitialMainPhoto = () => {
        return field.value
            .find(photo => photo.main)?.image;
    }

    const getInitialOrderedPhotos = () => {
        return field.value
            .filter(photo => photo.sequence !== undefined && photo.sequence !== null && !photo.main)
            .map(photo => {return {...photo.image, sequence: photo.sequence}})
    }

    const getInitialUnorderedPhotos = () => {
        return field.value
            .filter(photo => (photo.sequence === null || photo.sequence === undefined) && !photo.main)
            .map(photo => photo.image)
    }

    const handleOnChange = (mainPhoto: Image | undefined, orderedPhotos: OrderedImage[], unorderedImages: Image[]) => {
        const galleryImages: GalleryImage[] = [];

        if (mainPhoto) {
            galleryImages.push({image: mainPhoto, main: true, sequence: null})
        }

        orderedPhotos.forEach(photo => {
            galleryImages.push({image: photo, main: false, sequence: photo.sequence})
        })

        unorderedImages.forEach(photo => {
            galleryImages.push({image: photo, main: false, sequence: null})
        })

        setFieldValue(field.name, galleryImages);
    }

    return (
        <GalleryManager
            initialMainPhoto={getInitialMainPhoto()}
            initialOrderedPhotos={getInitialOrderedPhotos()}
            initialUnorderedPhotos={getInitialUnorderedPhotos()}
            onChange={handleOnChange}/>
    )
}

export default GalleryManagerField;