import React from "react";
import EventActivator from "./EventActivator";

interface ConditionalEventActivatorProps {
    condition: boolean,
    event: () => void
}

export const ConditionalEventActivator = ({condition, event}: ConditionalEventActivatorProps) => {
    return (
        <React.Fragment>
            {condition &&
                <EventActivator event={event}/>
            }
        </React.Fragment>
    );
};

export default ConditionalEventActivator;
