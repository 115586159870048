import {Dialog, DialogTitle} from "@mui/material";
import LoadingArea from "../Common/LoadingArea";
import Alert from "@mui/material/Alert";
import React from "react";
import {useMutation, useQuery} from "react-query";
import {imageKey} from "../../../Common/Api/queryKeys";
import {editImage, fetchImage} from "../../../Common/Api/image";
import EditPhotoForm, {EditPhotoFormValues} from "../Form/EditPhotoForm";
import {AxiosError} from "axios";
import {Image} from "../../../Common/Types/image";

interface EditPhotoDialogProps {
    activeId: number
    open: boolean,
    onClose: (saved: boolean) => void,
}

function EditPhotoDialog({activeId, open, onClose}: EditPhotoDialogProps) {
    const {
        data: image,
        isFetching,
        isError,
    } = useQuery(imageKey(activeId), fetchImage);

    const mutation = useMutation<Image, AxiosError<any>, Image, any>(editImage, {
        onSuccess: () => {
            onClose(true)
        }
    });

    const handleSubmit = ({watermarkImage, watermarkThumbnail}: EditPhotoFormValues) => {
        if (image) {
            mutation.mutate({
                ...image,
                watermarkImage,
                watermarkThumbnail
            })
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>Upravit atributy obrázku</DialogTitle>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity={"error"}>Chyba při načítání dat</Alert>}
            {!isFetching && !isError && image &&
                <EditPhotoForm
                    initialValues={{
                        watermarkThumbnail: image.watermarkThumbnail,
                        watermarkImage: image.watermarkImage
                    }}
                    onSubmit={handleSubmit}
                    onDialogClose={onClose}
                />
            }
        </Dialog>
    )
}

export default EditPhotoDialog