import ReactMarkdown from "react-markdown";
import PageParagraph from "../../Home/Components/Common/PageParagraph";

interface MarkdownProps {
    children: string
}

function Markdown({children}: MarkdownProps) {
    return (
        <ReactMarkdown children={children} components={{
            p: PageParagraph
        }}/>
    )
}

export default Markdown;