import ImageListPlaceholder from "../../Common/ImageListPlaceholder";

interface UnorderedListPlaceholderProps {
    label: "empty" | "nonempty"
}

function UnorderedListPlaceholder({label}: UnorderedListPlaceholderProps) {
    return (
        <ImageListPlaceholder>
            {label === "empty" && "Prázdné"}
            {label === "nonempty" && "Přesunout sem"}
        </ImageListPlaceholder>
    )
}

export default UnorderedListPlaceholder;