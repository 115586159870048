import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import {RecoilRoot} from "recoil";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import {RetryValue} from "react-query/types/core/retryer";
import {ParallaxProvider} from "react-scroll-parallax";
import {createInstance, MatomoProvider} from "@mitresthen/matomo-tracker-react";

// Repeat requests only if they're server-side problem
export const retryValue: RetryValue<any> = (failureCount, error) => {
    if (failureCount > 2)
        return false;

    return !error.response?.status || (error.response?.status >= 500 && error.response?.status < 600);
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: retryValue
        }
    }
});

const container = document.getElementById('root')!;
const root = createRoot(container);

const instance = createInstance({
    urlBase: 'https://matomo.ajvo.cz/',
    siteId: 1,
    linkTracking: false,
    heartBeat: {
        active: true,
        seconds: 5
    },
})

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <ParallaxProvider>
                <QueryClientProvider client={queryClient}>
                    <MatomoProvider value={instance}>
                        <App/>
                        <ReactQueryDevtools initialIsOpen={false}/>
                    </MatomoProvider>
                </QueryClientProvider>
            </ParallaxProvider>
        </RecoilRoot>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
