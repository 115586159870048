import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import LoadingArea from "../Common/LoadingArea";
import {Field, Formik, FormikProps} from "formik";
import GalleryManagerField from "../Field/GalleryManagerField";
import {AxiosError} from "axios";
import {GalleryImage} from "../../../Common/Types/galleryImage";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {galleryKey} from "../../../Common/Api/queryKeys";
import {fetchGallery, saveGallery} from "../../../Common/Api/gallery";
import {Gallery} from "../../../Common/Types/gallery";
import Alert from "@mui/material/Alert";

interface PhotosPreferenceDialogProps {
    open: boolean,
    galleryId: string,
    onClose: (saved: boolean) => void
}

function PhotosPreferenceDialogInner({galleryId, onClose}: PhotosPreferenceDialogProps) {
    const queryClient = useQueryClient()

    const {
        data: gallery,
        isFetching,
        isError,
    } = useQuery(galleryKey(galleryId), fetchGallery);

    const mutation = useMutation<Gallery, AxiosError<any>, Gallery, any>(saveGallery, {
        onSuccess: () => {
            const result = queryClient.cancelQueries();
            onClose(true)
            return result
        }
    });

    const onFormSubmit = (values: { galleryImages: GalleryImage[] }) => {
        if (gallery) {
            mutation.mutate({...gallery, ...values})
        }
    }

    return (
        <React.Fragment>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity="error">Chyba při načítání dat</Alert> }
            {!isFetching && !isError && gallery &&
                <Formik
                    initialValues={{
                        galleryImages: gallery.galleryImages
                    }}
                    onSubmit={onFormSubmit}
                >
                    {({handleSubmit, submitForm}: FormikProps<any>) => (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>Nastavit preference</DialogTitle>
                            <DialogContent>
                                <Field name="galleryImages" component={GalleryManagerField}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => onClose(false)}>Zavřít</Button>
                                <Button onClick={submitForm} variant="contained">Uložit</Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            }
        </React.Fragment>
    )
}

function PhotosPreferenceDialog(props: PhotosPreferenceDialogProps) {
    return (
        <Dialog open={props.open} onClose={() => props.onClose(false)} maxWidth="md" fullWidth>
            <PhotosPreferenceDialogInner {...props} />
        </Dialog>
    )
}

export default PhotosPreferenceDialog;