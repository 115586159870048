import {useMediaQuery, useTheme} from "@mui/material";

function useScaledImageLoader(imageSrc: string) {
    const theme = useTheme();

    const activeSize: Record<string, boolean> = {
        xs: useMediaQuery(theme.breakpoints.only("xs")),
        sm: useMediaQuery(theme.breakpoints.only("sm")),
        md: useMediaQuery(theme.breakpoints.only("md")),
        lg: useMediaQuery(theme.breakpoints.only("lg")),
        xl: useMediaQuery(theme.breakpoints.only("xl"))
    }

    return require(`../Assets/${Object.keys(activeSize).filter(key => activeSize[key]).pop()}/${imageSrc}`);
}

export default useScaledImageLoader;