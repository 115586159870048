import ImageListPlaceholder from "../../Common/ImageListPlaceholder";

interface MainPhotoPlaceholderProps {
    label: "select" | "replace"
}

function MainPhotoPlaceholder({label}: MainPhotoPlaceholderProps) {
    return (
        <ImageListPlaceholder>
            {label === "select" && "Vybrat"}
            {label === "replace" && "Nahradit"}
        </ImageListPlaceholder>
    )
}

export default MainPhotoPlaceholder;