import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import React from "react";
import {useMutation} from "react-query";
import {deleteGallery} from "../../../Common/Api/gallery";
import {AxiosError} from "axios";


interface DeleteGalleryDialogProps {
    galleryId: string,
    open: boolean,
    onClose: (deleted: boolean) => void,
}

function DeleteGalleryDialog({galleryId, open, onClose}: DeleteGalleryDialogProps) {
    const {
        mutate,
        isLoading
    } = useMutation<void, AxiosError, string, any>(deleteGallery, {
        onSuccess: () => {
            onClose(true)
        }
    })

    const handleDeleteGallery = () => {
        mutate(galleryId)
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Skutečně smazat tuto galerii?</DialogTitle>
            <DialogActions>
                <Button onClick={() => onClose(false)} disabled={isLoading}>Zrušit</Button>
                <Button variant="contained" onClick={handleDeleteGallery} disabled={isLoading} autoFocus>Smazat</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteGalleryDialog