import axios, {AxiosError} from "axios";
import {QueryFunctionContext, QueryKey} from "react-query/types/core/types";
import {Gallery} from "../Types/gallery";

export type GalleryId = { galleryId: string }

function fetchAllGalleries(): Promise<Gallery[]> {
    return axios.get("/api/galleries")
        .then(res => res.data);
}

function fetchGallery({queryKey}: QueryFunctionContext<[QueryKey, GalleryId]>): Promise<Gallery> {
    const [, {galleryId}] = queryKey;
    return axios.get(`/api/gallery/${galleryId}`)
        .then(res => res.data);
}

function fetchGalleryExists({queryKey}: QueryFunctionContext<[QueryKey, GalleryId]>): Promise<boolean> {
    const [, {galleryId}] = queryKey;
    return axios.head(`/api/gallery/${galleryId}`)
        .then(() => new Promise<boolean>(resolve => resolve(true)))
        .catch((error: AxiosError) => {
            if (error.response?.status === 404) {
                return new Promise<boolean>(resolve => resolve(false))
            }
            throw error
        })
}

function createGallery(data: Gallery): Promise<any> {
    return axios.post("/api/galleries", data)
}

function saveGallery(data: Gallery): Promise<any> {
    return axios.put(`/api/gallery/${data.id}`, data)
}

function deleteGallery(galleryId: string): Promise<any> {
    return axios.delete(`/api/gallery/${galleryId}`)
}

export {fetchAllGalleries, fetchGallery, fetchGalleryExists, createGallery, saveGallery, deleteGallery};