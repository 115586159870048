import React from "react";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PhotoIcon from '@mui/icons-material/Photo';
import CollectionsIcon from '@mui/icons-material/Collections';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {Link, useLocation, useNavigate} from "react-router-dom";
import EventActivator from "../../Common/Components/EventActivator/EventActivator";
import {ADMINISTRATION_AVAILABLE} from "../../Common/Storage/localStorageKeys";

interface AdminDrawerProps {
    children: React.ReactNode
}

const drawerWidth = 240;

const container = window !== undefined ? () => window.document.body : undefined;

const appBarStyles = {
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    ml: { sm: `${drawerWidth}px` },
}

const drawerContainerStyles = {
    width: { sm: drawerWidth }, flexShrink: { sm: 0 }
}

const mobileDrawerStyles = {
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
}

const pcDrawerStyles = {
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
}

const contentStyles = {
    flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },
    backgroundColor: "rgb(245, 245, 245)",
    minHeight: "100vh",
    boxSizing: "border-box"
}

const getPageHeading = (route: string): string => {
    switch (route) {
        case "/admin":
            return "Hlavní stránka";
        case "/admin/photos":
            return "Fotky";
        case "/admin/galleries":
            return "Galerie";
        case "/admin/settings":
            return "Nastavení";
        default:
            return "";
    }
}

const handleMarkAdministrationAvailable = () => {
    localStorage.setItem(ADMINISTRATION_AVAILABLE, "1");
}

function DrawerContent() {
    return (
        <div>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Administrace
                </Typography>
            </Toolbar>
            <Divider/>
            <List>
                <ListItem>
                    <ListItemButton component={Link} to="">
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Hlavní stránka"/>
                    </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemButton component={Link} to="photos">
                        <ListItemIcon>
                            <PhotoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Fotky"/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton component={Link} to="galleries">
                        <ListItemIcon>
                            <CollectionsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Galerie"/>
                    </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemButton component={Link} to="settings">
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Nastavení"/>
                    </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemButton component="a" href="/logout">
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Odhlásit"/>
                    </ListItemButton>
                </ListItem>
            </List>

            <EventActivator event={handleMarkAdministrationAvailable}/>
        </div>
    );
}

function AdminDrawer({children}: AdminDrawerProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={appBarStyles}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div">
                        {getPageHeading(location.pathname)}
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Button onClick={() => navigate("/")} sx={{color: "white"}}>Hlavní stránka webu</Button>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={drawerContainerStyles}>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={mobileDrawerStyles}>
                    <DrawerContent/>
                </Drawer>
                <Drawer variant="permanent" sx={pcDrawerStyles} open>
                    <DrawerContent/>
                </Drawer>
            </Box>

            <Box component="main" sx={contentStyles}>
                <Toolbar />

                {children}
            </Box>
        </Box>
    );
}

export default AdminDrawer;