import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {Tooltip} from "@mui/material";
import React from "react";

function NoWatermarkWarningSign() {
    return (
        <Tooltip title="Obrázek bez vodoznaku">
            <ReportProblemIcon sx={{color: "gold"}}/>
        </Tooltip>
    )
}

export default NoWatermarkWarningSign