import React, {useState} from "react";
import {
    Avatar,
    AvatarGroup,
    Button,
    Container,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    Tooltip,
    Typography
} from "@mui/material";
import NewGalleryDialog from "../Components/Dialogs/NewGalleryDialog";
import LoadingArea from "../Components/Common/LoadingArea";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Filter1Icon from '@mui/icons-material/Filter1';
import PhotosPreferenceDialog from "../Components/Dialogs/PhotosPreferenceDialog";
import SelectImagesDialog from "../Components/Dialogs/SelectImagesDialog";
import {useQuery, useQueryClient} from "react-query";
import {galleriesKey} from "../../Common/Api/queryKeys";
import {fetchAllGalleries} from "../../Common/Api/gallery";
import DeleteGalleryDialog from "../Components/Dialogs/DeleteGalleryDialog";
import DescriptionIcon from '@mui/icons-material/Description';
import EditGalleryDialog from "../Components/Dialogs/EditGalleryDialog";

interface GalleriesListProps {
    handleAssignPhotos: (galleryId: string) => void,
    handleSetPhotosPreferences: (galleryId: string) => void,
    handleEditGallery: (galleryId: string) => void,
    handleDeleteGallery: (galleryId: string) => void
}

function GalleriesList({handleAssignPhotos, handleEditGallery, handleDeleteGallery, handleSetPhotosPreferences}: GalleriesListProps) {
    const {
        data: galleries,
        isFetching,
        isError,
    } = useQuery(galleriesKey(), fetchAllGalleries);

    return (
        <TableContainer component={Paper} sx={{mt: 3}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Název</TableCell>
                        <TableCell>Popis</TableCell>
                        <TableCell>V portfoliu</TableCell>
                        <TableCell sx={{textAlign: "right"}}>Obrázky</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isFetching &&
                        <TableRow>
                            <TableCell colSpan={4}><LoadingArea/></TableCell>
                        </TableRow>
                    }
                    {!isFetching && isError &&
                        <TableRow>
                            <TableCell colSpan={4}>Chyba při načítání dat</TableCell>
                        </TableRow>
                    }
                    {!isFetching && !isError && galleries && galleries.map(gallery =>
                        <TableRow key={gallery.id}>
                            <TableCell>{gallery.id}</TableCell>
                            <TableCell>{gallery.name}</TableCell>
                            <TableCell>
                                {gallery.description &&
                                    <Tooltip title={gallery.description}>
                                        <IconButton>
                                            <DescriptionIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </TableCell>
                            <TableCell>
                                {gallery.showInPortfolio ? "ano" : "ne"}
                            </TableCell>
                            <TableCell sx={{textAlign: "right"}}>
                                {gallery.galleryImages.length > 0 &&
                                    <AvatarGroup max={4} variant="square" spacing={0}>
                                        {gallery.galleryImages.map(galleryImage => (
                                            <Avatar
                                                key={`${gallery.id}-${galleryImage.image.id}`}
                                                src={`/api/image/${galleryImage.image.id}/serve?thumbnail=true`}/>
                                        ))}
                                    </AvatarGroup>
                                }
                                {gallery.galleryImages.length === 0 &&
                                    <Typography variant="body1" color="text.secondary">Galerie je prázdná</Typography>
                                }
                            </TableCell>
                            <TableCell align="right">
                                <Tooltip title="Přiřadit obrázky">
                                    <IconButton onClick={() => handleAssignPhotos(gallery.id)} aria-label="Přiřadit obrázky">
                                        <AddPhotoAlternateIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Nastavit preference">
                                    <span>
                                        <IconButton onClick={() => handleSetPhotosPreferences(gallery.id)} disabled={gallery.galleryImages.length === 0} aria-label="Nastavit preference">
                                            <Filter1Icon/>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Upravit">
                                    <IconButton onClick={() => handleEditGallery(gallery.id)} aria-label="Upravit">
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Smazat">
                                    <IconButton onClick={() => handleDeleteGallery(gallery.id)} aria-label="Smazat">
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function GalleriesPage() {
    const queryClient = useQueryClient()

    const [newGalleryDialogOpen, setNewGalleryDialogOpen] = useState<boolean>(false);
    const [selectImagesDialogOpen, setSelectImagesDialogOpen] = useState<boolean>(false);
    const [photosPreferenceDialogOpen, setPhotosPreferencesDialogOpen] = useState<boolean>(false);
    const [editGalleryDialogOpen, setEditGalleryDialogOpen] = useState<boolean>(false);
    const [deleteGalleryDialogOpen, setDeleteGalleryDialogOpen] = useState<boolean>(false);

    const [activeGallery, setActiveGallery] = useState<string>('');

    const galleriesRefresh = () => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries(galleriesKey())
    };

    const newGalleryDialogClosed = (saved: boolean) => {
        setNewGalleryDialogOpen(false);
        if (saved) {
            galleriesRefresh()
        }
    }

    const handleSelectImagesDialogClosed = (saved: boolean) => {
        setSelectImagesDialogOpen(false);
        if (saved) {
            galleriesRefresh()
        }
    }

    const handlePhotosPreferenceDialogClosed = (saved: boolean) => {
        setPhotosPreferencesDialogOpen(false);
        if (saved) {
            galleriesRefresh();
        }
    }

    const handleEditGalleryDialogClosed = (saved: boolean) => {
        setEditGalleryDialogOpen(false);
        if (saved) {
            galleriesRefresh();
        }
    }

    const handleDeleteGalleryDialogClosed = (deleted: boolean) => {
        setDeleteGalleryDialogOpen(false);
        if (deleted) {
            galleriesRefresh();
        }
    }

    return (
        <Container>
            <Button variant="contained" onClick={() => setNewGalleryDialogOpen(true)}>Nová galerie</Button>

            <GalleriesList
                handleAssignPhotos={(galleryId) => {setActiveGallery(galleryId); setSelectImagesDialogOpen(true)}}
                handleSetPhotosPreferences={(galleryId) => {setActiveGallery(galleryId); setPhotosPreferencesDialogOpen(true)}}
                handleEditGallery={(galleryId) => {setActiveGallery(galleryId); setEditGalleryDialogOpen(true)}}
                handleDeleteGallery={(galleryId) => {setActiveGallery(galleryId); setDeleteGalleryDialogOpen(true)}}/>

            {newGalleryDialogOpen && <NewGalleryDialog open={newGalleryDialogOpen} onClose={newGalleryDialogClosed}/>}
            {selectImagesDialogOpen && <SelectImagesDialog open={selectImagesDialogOpen} galleryId={activeGallery} onClose={handleSelectImagesDialogClosed}/>}
            {photosPreferenceDialogOpen && <PhotosPreferenceDialog open={photosPreferenceDialogOpen} galleryId={activeGallery} onClose={handlePhotosPreferenceDialogClosed}/>}
            {editGalleryDialogOpen && <EditGalleryDialog open={editGalleryDialogOpen} galleryId={activeGallery} onClose={handleEditGalleryDialogClosed}/>}
            {deleteGalleryDialogOpen && <DeleteGalleryDialog open={deleteGalleryDialogOpen} galleryId={activeGallery} onClose={handleDeleteGalleryDialogClosed}/>}
        </Container>
    );
}

export default GalleriesPage;