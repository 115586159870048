import React from "react";
import {Theme, Typography} from "@mui/material";
import {SxProps} from "@mui/system";

interface PageParagraphProps {
    children: React.ReactNode,
    sx?: SxProps<Theme>
}

const paragraphStyles: SxProps<Theme> = {
    fontWeight: 300,
    lineHeight: "180%",
    textAlign: "justify",
    marginTop: "1.5em",
    marginBottom: "1.5em"
}

function PageParagraph({children, sx}: PageParagraphProps) {
    return (
        <Typography variant="body1" component="p" sx={Object.assign({}, paragraphStyles, sx)}>{children}</Typography>
    )
}

export default PageParagraph;