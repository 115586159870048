import {useMutation, useQuery} from "react-query";
import {Gallery} from "../../../Common/Types/gallery";
import {AxiosError} from "axios";
import {createGallery, fetchGallery} from "../../../Common/Api/gallery";
import {galleryKey} from "../../../Common/Api/queryKeys";
import EditGalleryForm, {EditGalleryFormValues} from "../Form/EditGalleryForm";
import {Dialog, DialogTitle} from "@mui/material";
import React from "react";
import LoadingArea from "../Common/LoadingArea";
import Alert from "@mui/material/Alert";

interface EditGalleryDialogProps {
    open: boolean,
    galleryId: string,
    onClose: (saved: boolean) => void
}

function EditGalleryDialog({open, galleryId, onClose = () => {}}: EditGalleryDialogProps) {
    const {
        data: gallery,
        isFetching,
        isError,
    } = useQuery(galleryKey(galleryId), fetchGallery);

    const mutation = useMutation<Gallery, AxiosError<any>, Gallery, any>(createGallery, {
        onSuccess: () => {
            onClose(true)
        }
    });

    const handleSubmit = ({name, description, showInPortfolio}: EditGalleryFormValues) => {
        mutation.mutate({
            id: galleryId,
            name: name,
            description: description,
            showInPortfolio: showInPortfolio,
            galleryImages: []
        })
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Upravit galerii</DialogTitle>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity={"error"}>Chyba při načítání dat</Alert>}
            {!isFetching && !isError && gallery &&
                <EditGalleryForm
                    initialValues={{
                        id: gallery.id,
                        name: gallery.name,
                        description: gallery.description,
                        showInPortfolio: gallery.showInPortfolio,
                        customId: false
                    }}
                    onSubmit={handleSubmit}
                    onDialogClose={onClose}/>
            }
        </Dialog>
    );
}

export default EditGalleryDialog;