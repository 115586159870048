import React from "react";
import {Box, CircularProgress} from "@mui/material";

function LoadingArea() {
    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", p: 5}}>
            <CircularProgress />
        </Box>
    );
}

export default LoadingArea;