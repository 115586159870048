import React from "react";
import {FieldProps} from "formik/dist/Field";
import {GalleryImage} from "../../../Common/Types/galleryImage";
import ImageSelector from "../ImageSelector/ImageSelector";
import {useQuery} from "react-query";
import {availableImagesForGalleryKey} from "../../../Common/Api/queryKeys";
import {fetchAvailableImagesForGallery} from "../../../Common/Api/image";
import LoadingArea from "../Common/LoadingArea";
import Alert from "@mui/material/Alert";

interface ImageSelectorFieldProps extends FieldProps<GalleryImage[]> {
    galleryId: string
}

function ImageSelectorField({galleryId, field, form: {setFieldValue}}: ImageSelectorFieldProps) {
    const {
        data: availablePhotos,
        isFetching,
        isError
    } = useQuery(
        availableImagesForGalleryKey(galleryId),
        fetchAvailableImagesForGallery
    )

    const handleOnChange = (selectedPhotos: GalleryImage[]) => {
        setFieldValue(field.name, selectedPhotos);
    }

    return (
        <React.Fragment>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity={"error"}>Chyba při načítání dat</Alert>}
            {!isFetching && !isError && availablePhotos &&
                <ImageSelector
                    initialSelectedPhotos={field.value}
                    initialUnassignedPhotos={availablePhotos.unassigned}
                    initialOtherPhotos={availablePhotos.others}
                    onChange={handleOnChange}
                />
            }
        </React.Fragment>
    )
}

export default ImageSelectorField