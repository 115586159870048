import {Box, Button, DialogActions, DialogContent, Stack, Typography} from "@mui/material";
import {Field, Formik} from "formik";
import {CheckboxWithLabel, TextField} from "formik-mui";
import React, {useEffect, useRef} from "react";
import * as Yup from "yup";
import axios, {AxiosError, AxiosResponse} from "axios";
import Markdown from "../../../Common/Components/Markdown";

export const nameToId = (name: string): string => {
    name = name.toLowerCase();
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    name = name.replaceAll(/[^a-zA-Z0-9]+/ig, "-");
    name = name.replace(/^-/, "").replace(/-$/, "");
    return name;
}

export interface EditGalleryFormValues {
    customId: boolean,
    id: string,
    name: string,
    description: string,
    showInPortfolio: boolean
}

interface EditGalleryFormProps {
    initialValues: EditGalleryFormValues
    onSubmit: (values: EditGalleryFormValues) => void,
    onDialogClose: (saved: boolean) => void
}

function EditGalleryForm({initialValues, onSubmit, onDialogClose}: EditGalleryFormProps) {
    const isNewGallery: boolean = !initialValues.id;

    const focusElementRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        focusElementRef.current?.focus()
    }, [])

    const checkId = (id: string | undefined): boolean => {
        if (!id) {
            return false;
        }

        if (!isNewGallery) {
            // No need to check validity of ID when editing
            return true;
        }

        return id === nameToId(id);
    }

    const checkIdUnique = async (id: string | undefined): Promise<boolean> => {
        if (!checkId(id)) {
            return false;
        }

        if (!isNewGallery) {
            // No need to check existence of gallery when editing
            return true;
        }

        try {
            const response: AxiosResponse<boolean> = await axios.get(`/api/gallery/${id}`);
            if (response.status === 200) {
                return false;
            }
        } catch (error) {
            const errorTyped = error as AxiosError;
            if (errorTyped.response?.status === 404) {
                return true;
            } else {
                throw error;
            }
        }

        throw "Unexpected state";
    }

    const editGalleryformSchema = Yup.object({
        id: Yup
            .string()
            .required("ID nesmí být prázdné")
            .test(
                "match-name-to-id",
                "ID musí obsahovat pouze malá písmena bez diakritiky, pomlčky a čísla",
                checkId
            )
            .test(
                "gallery-id-unique",
                "Galerie s tímto ID již existuje",
                checkIdUnique
            )
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={editGalleryformSchema}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
        >
            {({
                  values,
                  handleSubmit,
                  isSubmitting,
                  handleChange,
                  setFieldValue
              }) => {
                const handleChangeNameField = (e: React.ChangeEvent<any>) => {
                    handleChange(e);
                    !values.customId && setFieldValue("id", nameToId(e.target.value))
                }

                return (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={2}>
                                    <Stack spacing={2}>
                                        {initialValues.id &&
                                            <Typography component="span">ID: {initialValues.id}</Typography>
                                        }
                                        {!initialValues.id &&
                                            <React.Fragment>
                                                <Field component={CheckboxWithLabel} type="checkbox" name="customId"
                                                       Label={{label: "Vlastní ID"}}/>
                                                <Field component={TextField} name="id" type="text" label="ID" disabled={!values.customId}/>
                                            </React.Fragment>
                                        }
                                        <Field component={TextField} name="name" type="text" label="Název" inputRef={focusElementRef} onChange={handleChangeNameField}/>
                                        <Field component={CheckboxWithLabel} name="showInPortfolio" checked={values.showInPortfolio} Label={{label: "Zobrazit v portfoliu"}}/>
                                    </Stack>
                                    <Field component={TextField} name="description" placeholder="Popis" multiline={true} rows={7} style={{width: "100%"}}/>
                                </Stack>
                                <Box sx={{height: 200, overflowY: "auto", border: "1px solid silver", borderRadius: 1, px: 3}}>
                                    <Markdown children={values.description}/>
                                </Box>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onDialogClose(false)} disabled={isSubmitting}>Zrušit</Button>
                            <Button type="submit" variant="contained" disabled={isSubmitting}>Uložit</Button>
                        </DialogActions>
                    </form>
                )
            }}
        </Formik>
    )
}

export default EditGalleryForm;