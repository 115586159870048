import PageHeading from "../Components/Common/PageHeading";
import React from "react";
import {Container} from "@mui/material";
import {useParams} from "react-router-dom";
import LoadingArea from "../../Admin/Components/Common/LoadingArea";
import Alert from "@mui/material/Alert";
import {useQuery} from "react-query";
import {galleryKey} from "../../Common/Api/queryKeys";
import {fetchGallery} from "../../Common/Api/gallery";
import {AxiosError} from "axios";
import {Gallery} from "../../Common/Types/gallery";
import ImageGallery, {Image} from "../Components/ImageGallery/ImageGallery";
import {GalleryImage} from "../../Common/Types/galleryImage";
import PageBackLink from "../Components/Common/PageBackLink";
import Markdown from "../../Common/Components/Markdown";

interface GalleryPageProps {
    topMenuHeight: number
}

function GalleryPage({topMenuHeight}: GalleryPageProps) {
    const { galleryId } = useParams<string>();

    const {
        data: gallery,
        isFetching,
        isError,
    } = useQuery<any, AxiosError, Gallery, any>(
        galleryKey(galleryId || ""),
        fetchGallery
    );

    const convertGalleryImage = (galleryImage: GalleryImage): Image => {
        return {
            imageUrl: "/api/image/" + galleryImage.image.id + "/serve",
            thumbnailUrl: "/api/image/" + galleryImage.image.id + "/serve?thumbnail=true",
            imageWidth: galleryImage.image.imageWidth,
            imageHeight: galleryImage.image.imageHeight,
        }
    }

    return (
        <Container sx={{pt: topMenuHeight + "px"}}>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity="error">Chyba při načítání galerie</Alert> }
            {!isFetching && !isError && gallery &&
                <React.Fragment>
                    <PageHeading>{gallery.name}</PageHeading>

                    <PageBackLink to="/nabidka-foceni"></PageBackLink>

                    {gallery.description &&
                        <React.Fragment>
                            <Markdown children={gallery.description} />
                        </React.Fragment>
                    }

                    <ImageGallery id={gallery.id} images={gallery.galleryImages.map(convertGalleryImage)}/>
                </React.Fragment>
            }
        </Container>
    )
}

export default GalleryPage;