import {Image} from "../../../Common/Types/image";
import React, {useEffect, useState} from "react";
import {GalleryImage} from "../../../Common/Types/galleryImage";
import {Typography} from "@mui/material";
import ImageSelectorGroup from "./ImageSelectorGroup/ImageSelectorGroup";
import ImageSelectorSelectedGroup from "./ImageSelectorSelectedGroup/ImageSelectorSelectedGroup";
import update from "immutability-helper";

interface ImageSelectorProps {
    initialSelectedPhotos: GalleryImage[],
    initialUnassignedPhotos: Image[],
    initialOtherPhotos: Image[],
    onChange: (selectedPhotos: GalleryImage[]) => void
}

function ImageSelector({initialSelectedPhotos, initialUnassignedPhotos, initialOtherPhotos, onChange}: ImageSelectorProps) {
    const [selectedPhotos, setSelectedPhotos] = useState<GalleryImage[]>(initialSelectedPhotos);
    const [deselectedPhotos, setDeselectedPhotos] = useState<GalleryImage[]>([]);
    const [unassignedPhotos, setUnassignedPhotos] = useState<Image[]>(initialUnassignedPhotos);
    const [otherPhotos, setOtherPhotos] = useState<Image[]>(initialOtherPhotos);

    useEffect(() => {
        onChange(selectedPhotos)
    }, [selectedPhotos])

    const removeSelectedPhoto = (index: number): GalleryImage => {
        const removedPhoto = selectedPhotos[index];
        setSelectedPhotos((prevSelectedPhotos: GalleryImage[]) =>
            update(prevSelectedPhotos, {
                $splice: [
                    [index, 1]
                ]
            })
        )
        return removedPhoto
    }

    const addSelectedPhoto = (galleryImage: GalleryImage): void => {
        setSelectedPhotos((prevSelectedPhotos: GalleryImage[]) =>
            update(prevSelectedPhotos, {
                $push: [galleryImage]
            })
        )
    }

    const removeDeselectedPhoto = (index: number): GalleryImage => {
        const removedPhoto = deselectedPhotos[index];
        setDeselectedPhotos((prevDeselectedPhotos: GalleryImage[]) =>
            update(prevDeselectedPhotos, {
                $splice: [
                    [index, 1]
                ]
            })
        )
        return removedPhoto
    }

    const addDeselectedPhoto = (galleryImage: GalleryImage): void => {
        setDeselectedPhotos((prevDeselectedPhotos: GalleryImage[]) =>
            update(prevDeselectedPhotos, {
                $push: [galleryImage]
            })
        )
    }

    const removeUnassignedPhoto = (index: number): Image => {
        const removedPhoto = unassignedPhotos[index];
        setUnassignedPhotos((prevUnassignedPhotos) =>
            update(prevUnassignedPhotos, {
                $splice: [
                    [index, 1]
                ]
            })
        )
        return removedPhoto
    }

    const removeOtherPhoto = (index: number): Image => {
        const removedPhoto = otherPhotos[index];
        setOtherPhotos((prevOtherPhotos) =>
            update(prevOtherPhotos, {
                $splice: [
                    [index, 1]
                ]
            })
        )
        return removedPhoto
    }

    const moveToSelected = (imageId: number) => {
        const deslectedPhotosIndex = deselectedPhotos.findIndex(galleryImage => galleryImage.image.id === imageId)
        if (deslectedPhotosIndex !== -1) {
            const targetPhoto = removeDeselectedPhoto(deslectedPhotosIndex);
            addSelectedPhoto(targetPhoto)
        }

        const unassignedPhotosIndex = unassignedPhotos.findIndex(image => image.id === imageId)
        if (unassignedPhotosIndex !== -1) {
            const targetPhoto = removeUnassignedPhoto(unassignedPhotosIndex);
            addSelectedPhoto({image: targetPhoto, main: false, sequence: null})
        }

        const otherPhotosIndex = otherPhotos.findIndex(images => images.id === imageId)
        if (otherPhotosIndex !== -1) {
            const targetPhoto = removeOtherPhoto(otherPhotosIndex);
            addSelectedPhoto({image: targetPhoto, main: false, sequence: null})
        }
    }

    const moveToDeselected = (imageId: number) => {
        const selectedPhotosIndex = selectedPhotos.findIndex(image => image.image.id === imageId)
        if (selectedPhotosIndex !== -1) {
            const targetPhoto = removeSelectedPhoto(selectedPhotosIndex)
            addDeselectedPhoto(targetPhoto)
        }
    }

    return (
        <div>
            <Typography variant="caption">Vybrané</Typography>
            <ImageSelectorSelectedGroup
                photos={selectedPhotos}
                hoverLabel="Odebrat"
                onImageClick={moveToDeselected}/>

            {deselectedPhotos.length > 0 &&
                <React.Fragment>
                    <Typography variant="caption">Odebrané</Typography>
                    <ImageSelectorSelectedGroup
                        photos={deselectedPhotos}
                        hoverLabel="Znovu přidat"
                        onImageClick={moveToSelected}/>
                </React.Fragment>
            }

            <Typography variant="caption">Nezařazené</Typography>
            <ImageSelectorGroup photos={unassignedPhotos} onImageClick={moveToSelected}/>

            <Typography variant="caption">V jiných galeriích</Typography>
            <ImageSelectorGroup photos={otherPhotos} onImageClick={moveToSelected}/>
        </div>
    )
}

export default ImageSelector;