import React, {useState} from "react";
import {useDrop} from "react-dnd";
import {ImageList} from "@mui/material";

import {OrderedImage} from "../../../../Common/Types/orderedImage";
import OrderedListImage from "./OrderedListImage";
import OrderedListImagePlaceholder from "./OrderedListImagePlaceholder";

interface OrderedListProps {
    images: OrderedImage[],
    moveToOrdered: (imageId: number, position: number) => void
}

function OrderedList({images, moveToOrdered}: OrderedListProps) {
    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(images.length === 0);

    const [, dropRef] = useDrop<OrderedImage, void>({
        accept: "image",
        collect: (monitor) => {
            setShowPlaceholder(monitor.isOver() || images.length === 0);
        }
    });

    return (
        <ImageList ref={dropRef} cols={5} rowHeight={150}>
            {images.map(image => (
                <OrderedListImage key={image.id} image={image} moveToOrdered={moveToOrdered}/>
            ))}
            {showPlaceholder &&
                <OrderedListImagePlaceholder
                    position={images.length}
                    moveToOrdered={moveToOrdered}
                    label={images.length === 0 ? "empty" : "nonempty"}
                />
            }
        </ImageList>
    )
}

export default OrderedList;