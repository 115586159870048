import {Box, Button, Container, Typography} from "@mui/material";
import {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import useScaledImageLoader from "../../Hooks/useScaledImageLoader";
import {ParallaxBanner, ParallaxBannerLayer} from "react-scroll-parallax";

const contentStyles = (theme: any) => ({
    [theme.breakpoints.down('lg')]: {
        mx: 12,
    },
    [theme.breakpoints.down('md')]: {
        mx: 7,
    },
    [theme.breakpoints.down('sm')]: {
        mx: 3,
        py: 7,
        px: 5
    },
    height: "100%",
    boxSizing: "border-box",
    mx: 20,
    p: 10,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.3)",
    "& a": {
        color: "inherit"
    }
})

const headingStyles = {
    fontSize: "2.5em",
    fontWeight: 500
}

interface ContentWithLinkAndPhotoProps extends PropsWithChildren<any> {
    heading: string,
    link: string
    buttonText: string,
    imageSrc: string,
}

function ContentWithParallaxBackground({heading, link, buttonText, imageSrc, children}: ContentWithLinkAndPhotoProps) {
    const image = useScaledImageLoader(imageSrc);

    return (
        <ParallaxBanner style={{minHeight: "80vh"}}>
            <ParallaxBannerLayer image={image} speed={-15}/>
            <ParallaxBannerLayer style={{display: "flex", alignItems: "center"}}>
                <Container sx={{py: 15}}>
                    <Box sx={contentStyles}>
                        <Box>
                            <Typography variant="h2" sx={headingStyles}>{heading}</Typography>
                            {children &&
                                <p>{children}</p>
                            }
                            <Link to={link}><Button color="inherit" variant="outlined" size="large">{buttonText}</Button></Link>
                        </Box>
                    </Box>
                </Container>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    )
}

export default ContentWithParallaxBackground;