import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableFooter
} from "@mui/material";
import {Field, Formik} from "formik";
import {FieldProps} from "formik/dist/Field";
import ClearIcon from '@mui/icons-material/Clear';
import React, {ChangeEvent, useState} from "react";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {Checkbox as FormikCheckbox} from "formik-mui";
import update from "immutability-helper";
import {ImageToUpload, ImageToUploadWithProgressCallback} from "../../../Common/Types/imageToUpload";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {uploadImages} from "../../../Common/Api/image";

interface AddImageButtonProps extends FieldProps<ImageToUpload[]> {
    disabled: boolean
}

function AddImageButton({field: {name, value}, form: {setFieldValue}, disabled}: AddImageButtonProps) {
    const handleChange = ({target: {files}}: ChangeEvent<HTMLInputElement>) => {
        if (files) {
            const newFiles = [...value]
            for (let i = 0; i < files.length; i++) {
                const file = files.item(i)
                if (file !== null) {
                    const newValue: ImageToUpload = {
                        watermarkThumbnail: false,
                        watermarkImage: true,
                        file
                    }
                    newFiles.push(newValue)
                }
            }

            setFieldValue(name, newFiles)
        }
    }

    return (
        <label>
            <input type="file" accept="image/*" multiple={true} onChange={handleChange} disabled={disabled} style={{display: "none"}}/>
            <Button variant="contained" component="span" disabled={disabled}>Přidat soubory</Button>
        </label>
    )
}

interface NewPhotoDialogProps {
    open: boolean,
    onClose: (saved: boolean) => void
}

function NewPhotoDialog({open, onClose}: NewPhotoDialogProps) {
    const [progress, setProgress] = useState<{imageIndex: number, progress: number}[]>([])

    const initialValues: {images: ImageToUpload[]} = {
        images: []
    }

    const mutation = useMutation<void[], AxiosError, ImageToUploadWithProgressCallback[], any>(uploadImages, {
        onSuccess: () => {
            onClose(true)
        },
    })

    const handleFormSubmit = (values: {images: ImageToUpload[]}) => {
        setProgress(values.images.map((image, index) => { return {
            imageIndex: index,
            progress: 0
        }}))

        mutation.mutate(values.images.map((image, index) => { return {
            ...image,
            onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                setProgress(prevState =>
                    prevState.map(imageWithProgress =>
                        imageWithProgress.imageIndex === index
                            ? {...imageWithProgress, progress: percentCompleted}
                            : imageWithProgress)
                )
            }
        }}))
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>Nahrát soubor</DialogTitle>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
                {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Table>
                                {values.images.length > 0 &&
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell sx={{textAlign: "center"}}>Vodoznak obrázku</TableCell>
                                        <TableCell sx={{textAlign: "center"}}>Vodoznak miniatury</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                }
                                <TableBody>
                                    {values.images.map((image, index) =>
                                        <TableRow key={index}>
                                            <TableCell>
                                                <img
                                                    alt={image.file.name}
                                                    src={URL.createObjectURL(image.file)}
                                                    style={{maxWidth: 100, maxHeight: 100}}
                                                />
                                            </TableCell>
                                            <TableCell sx={{textAlign: "center"}}>
                                                <Field type="checkbox" name={`images[${index}].watermarkImage`} component={FormikCheckbox}/>
                                            </TableCell>
                                            <TableCell sx={{textAlign: "center"}}>
                                                <Field type="checkbox" name={`images[${index}].watermarkThumbnail`} component={FormikCheckbox}/>
                                            </TableCell>
                                            <TableCell>
                                                {!isSubmitting &&
                                                    <IconButton onClick={() => {
                                                        setFieldValue(
                                                            "images",
                                                            update(values.images, {
                                                                $splice: [
                                                                    [index, 1]
                                                                ]
                                                            }))
                                                    }} disabled={isSubmitting}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                }
                                                {isSubmitting && progress[index] &&
                                                    <CircularProgress
                                                        variant={progress[index].progress < 100 ? "determinate" : "indeterminate"}
                                                        value={progress[index].progress}/>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={4} sx={{textAlign: "center"}}>
                                            <Field name="images" component={AddImageButton} disabled={isSubmitting}/>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onClose(false)} disabled={isSubmitting}>Zrušit</Button>
                            <Button type="submit" variant="contained" disabled={values.images.length === 0 || isSubmitting}>Nahrát</Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default NewPhotoDialog