import React from "react";
import AdminDrawer from "../Components/AdminDrawer";
import {Route, Routes} from "react-router-dom";
import PhotosPage from "./PhotosPage";
import DashboardPage from "./DashboardPage";
import GalleriesPage from "./GalleriesPage";
import SettingsPage from "./SettingsPage";
import {useQuery} from "react-query";
import {fetchUserInfo} from "../../Common/Api/user";
import {userInfoKey} from "../../Common/Api/queryKeys";
import LoadingArea from "../Components/Common/LoadingArea";
import Alert from "@mui/material/Alert";
import ConditionalEventActivator from "../../Common/Components/EventActivator/ConditionalEventActivator";

function AdminPage() {
    const {
        isFetching,
        isError,
        data: user
    } = useQuery(userInfoKey(), fetchUserInfo)

    const isAdmin = user?.authorities.some(authority => authority.authority === "ROLE_ADMIN");

    return (
        <React.Fragment>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity={"error"}>Chyba při načítání dat</Alert>}
            {!isFetching && !isError && user && isAdmin &&
                <AdminDrawer>
                    <Routes>
                        <Route path="/" element={<DashboardPage/>}/>
                        <Route path="/photos" element={<PhotosPage/>}/>
                        <Route path="/galleries" element={<GalleriesPage/>}/>
                        <Route path="/settings" element={<SettingsPage/>}/>
                    </Routes>
                </AdminDrawer>
            }

            {!isFetching && user &&
                <ConditionalEventActivator
                    condition={!isAdmin}
                    event={() => {
                        window.location.href = "/login"
                    }}/>
            }
        </React.Fragment>
    );
}

export default AdminPage;