import MainPhotoPlaceholder from "./MainPhotoPlaceholder";
import {ImageList} from "@mui/material";
import {useDrop} from "react-dnd";
import {Image} from "../../../../Common/Types/image";
import MainPhotoImage from "./MainPhotoImage";
import {useState} from "react";


interface MainPhotoProps {
    image?: Image,
    selectMainPhoto: (imageId: number) => void
}

function MainPhoto({image, selectMainPhoto}: MainPhotoProps) {
    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(image === undefined);

    const [, dropRef] = useDrop<Image, void>({
        accept: "image",
        drop: (item) => {
            selectMainPhoto(item.id);
        },
        collect: (monitor) => {
            setShowPlaceholder(monitor.isOver() || image === undefined);
        }
    });

    return (
        <ImageList ref={dropRef} cols={5} rowHeight={150}>
            {image && !showPlaceholder &&
                <MainPhotoImage image={image}/>
            }
            {showPlaceholder &&
                <MainPhotoPlaceholder label={image === undefined ? "select" : "replace"}/>
            }
        </ImageList>
    )
}

export default MainPhoto;