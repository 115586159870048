import {Box, ImageListItem} from "@mui/material";
import React from "react";
import {Image} from "../../../../Common/Types/image";
import {useDrag} from "react-dnd";
import NoWatermarkWarningSign from "../../Common/NoWatermarkWarningSign";

interface UnorderedListImageProps {
    image: Image
}

function UnorderedListImage({image}: UnorderedListImageProps) {
    const [{opacity}, dragRef] = useDrag(
        () => ({
            type: "image",
            item: {id: image.id},
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }),
        []
    )

    return (
        <ImageListItem ref={dragRef}>
            <Box sx={{
                backgroundImage: `url(/api/image/${image.id}/serve?thumbnail=true)`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: 150,
                p: 1,
                opacity
            }}>
                {!image.watermarkImage &&
                    <NoWatermarkWarningSign/>
                }
            </Box>
        </ImageListItem>
    )
}

export default UnorderedListImage;