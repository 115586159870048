import React, {useRef} from "react";
import SplashScreen from "../Components/SplashScreen/SplashScreen";
import EntryHeader from "../Components/ContentParts/EntryHeader";
import ContentWithLinkAndPhoto from "../Components/ContentParts/ContentWithLinkAndPhoto";
import {Container, Divider} from "@mui/material";
import ContentWithParallaxBackground from "../Components/ContentParts/ContentWithParallaxBackground";

interface MainPageProps {
    topMenuHeight: number
}

function MainPage({topMenuHeight}: MainPageProps) {
    const pageContentRef = useRef<HTMLDivElement>(null);

    function scrollToContent() {
        const topMenuSize: number | undefined = topMenuHeight;
        const contentPosition: number | undefined = pageContentRef.current?.getBoundingClientRect()?.top;

        const scrollTo: number | undefined = (topMenuSize && contentPosition) ? contentPosition + window.scrollY - topMenuSize : undefined;

        if (scrollTo) {
            window.scrollTo({top: scrollTo, behavior: "smooth"});
        }
    }

    return (
        <React.Fragment>
            <SplashScreen onScrollArrowClicked={scrollToContent}/>

            <div ref={pageContentRef}/>

            <EntryHeader heading={"Rodinná fotografka z jižní Moravy"}>
                Vaše emoce, smích, láska a radost zachycena na jedinečných snímcích. <br/>Nefotím jen tváře, fotím příběhy.
            </EntryHeader>

            <ContentWithLinkAndPhoto heading="Nabídka focení" link="/nabidka-foceni" buttonText="Nabídka focení" imageSrc="portfolio-page-part-image.jpg" textPosition="left" background={true}>
                Mé osobní portfolio obsahující ukázku portrétních, rodinných a novorozeneckých fotografií.
            </ContentWithLinkAndPhoto>

            <ContentWithLinkAndPhoto heading="Ceník" link="/cenik" buttonText="Ceník" imageSrc="price-list-page-part-image.jpg" textPosition="right" background={false}>
                Přehledný ceník mých veškerých fotografických služeb.
            </ContentWithLinkAndPhoto>

            <ContentWithParallaxBackground heading="O mně" link="/o-mne" buttonText="Více" imageSrc="about-me-page-part-image.jpg">
                Mé jméno je Lenka Hlavničková a jsem začínající fotografkou na Jižní Moravě. V současnosti bydlím v malé obci Šanov v okrese Znojmo, ale kus mého srdce zůstal v Brně, kde jsem 9 let žila a studovala, proto ráda jezdím fotit také tam&#8230;
            </ContentWithParallaxBackground>

            <Container>
                <Divider sx={{ width: "80%", borderWidth: 1, margin: "50px auto", borderColor: "rgba(201,201,201,.4)" }} />
            </Container>

            <ContentWithParallaxBackground heading="Podmínky spolupráce" link="/podminky" buttonText="Přečíst zde" imageSrc="terms-and-conditions-page-part-image.jpg">
                Pročtěte si, prosím, před začátkem focení. Děkuji za respektování.
            </ContentWithParallaxBackground>
        </React.Fragment>
    );
}

export default MainPage;