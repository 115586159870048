import React from "react";
import {Box} from "@mui/material";
import {Property} from "csstype";

interface RainbowPicturesLogoProps {
    size: number,
    color: Property.Color,
}

function RainbowPicturesLogo({size, color}: RainbowPicturesLogoProps) {
    return (
        <Box sx={{width: size, height: size}}>
            <svg width="100%" height="100%" viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{fillRule:"evenodd",clipRule:"evenodd",strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:1.5}}>
                <g transform="matrix(1.04365,0,0,1.04365,-622.865,-272.475)">
                    <g transform="matrix(0.982073,0,0,0.982073,-6.69648,-9.62491)">
                        <circle cx="687.702" cy="348.772" r="48.729" style={{fill:"none",stroke:color,strokeWidth:0.98}}/>
                    </g>
                    <g transform="matrix(0.896576,0,0,0.896576,52.1,20.194)">
                        <circle cx="687.702" cy="348.772" r="48.729" style={{fill:"none",stroke:color,strokeWidth:1.07}}/>
                    </g>
                    <g transform="matrix(1.04739,0,0,1.04739,-51.6152,-32.4057)">
                        <circle cx="687.702" cy="348.772" r="65.266" style={{fill:"none",stroke:color,strokeWidth:2.74}}/>
                    </g>
                    <g transform="matrix(0.874686,0,0,0.874686,275.068,225.013)">
                        <circle cx="450" cy="123.337" r="20.325" style={{fill:color}}/>
                    </g>
                    <g transform="matrix(0.830231,0,0,0.830231,148.496,235.621)">
                        <g id="Clona">
                            <circle cx="626.551" cy="117.164" r="48.372" style={{fill:"none"}}/>
                            <clipPath id="_clip1">
                                <circle cx="626.551" cy="117.164" r="48.372"/>
                            </clipPath>
                            <g clipPath="url(#_clip1)">
                                <g transform="matrix(0.76116,0,0,0.76116,284.028,23.2844)">
                                    <path d="M439.519,222.48L415.545,118.21L370.589,176.087L439.519,222.48ZM520.956,193.369L424.502,147.07L441.699,218.31L520.956,193.369ZM548.997,111.557L452.646,158.071L519.056,189.064L548.997,111.557ZM365.969,176.984L432.517,93.209L359.237,94.169L365.969,176.984ZM502.541,38.611L478.799,142.933L544.447,110.357L502.541,38.611ZM416.546,29.423L483.28,113.049L498.764,41.418L416.546,29.423ZM355.727,90.908L462.717,90.908L416.386,34.125L355.727,90.908Z" style={{fill:color}}/>
                                    <clipPath id="_clip2">
                                        <path d="M439.519,222.48L415.545,118.21L370.589,176.087L439.519,222.48ZM520.956,193.369L424.502,147.07L441.699,218.31L520.956,193.369ZM548.997,111.557L452.646,158.071L519.056,189.064L548.997,111.557ZM365.969,176.984L432.517,93.209L359.237,94.169L365.969,176.984ZM502.541,38.611L478.799,142.933L544.447,110.357L502.541,38.611ZM416.546,29.423L483.28,113.049L498.764,41.418L416.546,29.423ZM355.727,90.908L462.717,90.908L416.386,34.125L355.727,90.908Z"/>
                                    </clipPath>
                                    <g clipPath="url(#_clip2)">
                                        <circle cx="450" cy="123.337" r="63.55" style={{fill:color}}/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g transform="matrix(0.958175,0,0,0.958175,9.29752,-12.162)">
                        <g id="Rainbow-text">
                            <g>
                                <g transform="matrix(0.76955,-0.638586,0.638586,0.76955,-94.6128,559.093)">
                                    <g transform="matrix(7,0,0,7,727.329,410.635)">
                                        <path d="M0.448,-0L0.355,-0.205C0.336,-0.247 0.317,-0.276 0.297,-0.29C0.278,-0.305 0.254,-0.312 0.225,-0.312L0.138,-0.312L0.138,-0L0.09,-0L0.09,-0.7L0.284,-0.7C0.346,-0.7 0.395,-0.684 0.43,-0.65C0.465,-0.617 0.483,-0.573 0.483,-0.518C0.483,-0.468 0.468,-0.425 0.439,-0.392C0.41,-0.358 0.37,-0.336 0.318,-0.326L0.318,-0.324C0.346,-0.313 0.373,-0.279 0.399,-0.223L0.503,-0L0.448,-0ZM0.138,-0.656L0.138,-0.356L0.259,-0.356C0.312,-0.356 0.355,-0.37 0.386,-0.398C0.417,-0.426 0.433,-0.464 0.433,-0.513C0.433,-0.558 0.419,-0.593 0.391,-0.618C0.362,-0.643 0.322,-0.656 0.269,-0.656L0.138,-0.656Z" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                                <g transform="matrix(0.705526,-0.708684,0.708684,0.705526,-72.7363,638.452)">
                                    <g transform="matrix(7,0,0,7,731.884,406.75)">
                                        <path d="M0.546,-0L0.466,-0.218L0.162,-0.218L0.082,-0L0.029,-0L0.293,-0.7L0.339,-0.7L0.6,-0L0.546,-0ZM0.331,-0.586C0.327,-0.596 0.325,-0.605 0.322,-0.615C0.32,-0.623 0.318,-0.631 0.315,-0.639L0.313,-0.639C0.307,-0.618 0.302,-0.6 0.297,-0.587L0.179,-0.262L0.449,-0.262L0.331,-0.586Z" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                                <g transform="matrix(0.647296,-0.762239,0.762239,0.647296,-46.7392,703.092)">
                                    <g transform="matrix(7,0,0,7,736.367,402.051)">
                                        <rect x="0.09" y="-0.7" width="0.048" height="0.7" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                                <g transform="matrix(0.58118,-0.813775,0.813775,0.58118,-15.4667,768.377)">
                                    <g transform="matrix(7,0,0,7,738.752,399.214)">
                                        <path d="M0.585,-0L0.168,-0.566C0.16,-0.577 0.152,-0.589 0.146,-0.6L0.139,-0.61L0.137,-0.61C0.138,-0.601 0.138,-0.591 0.138,-0.579L0.138,-0L0.09,-0L0.09,-0.7L0.127,-0.7L0.541,-0.139C0.55,-0.127 0.56,-0.112 0.571,-0.094L0.573,-0.094C0.572,-0.117 0.571,-0.137 0.571,-0.153L0.571,-0.7L0.619,-0.7L0.619,-0L0.585,-0Z" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                                <g transform="matrix(0.494401,-0.869234,0.869234,0.494401,33.5759,844.521)">
                                    <g transform="matrix(7,0,0,7,742.745,393.398)">
                                        <path d="M0.09,-0L0.09,-0.7L0.274,-0.7C0.329,-0.7 0.373,-0.685 0.405,-0.656C0.438,-0.626 0.454,-0.587 0.454,-0.538C0.454,-0.497 0.443,-0.462 0.422,-0.432C0.4,-0.403 0.37,-0.382 0.331,-0.369L0.331,-0.367C0.38,-0.363 0.418,-0.345 0.446,-0.315C0.474,-0.285 0.488,-0.247 0.488,-0.2C0.488,-0.141 0.469,-0.093 0.431,-0.056C0.393,-0.019 0.342,-0 0.278,-0L0.09,-0ZM0.138,-0.656L0.138,-0.385L0.236,-0.385C0.287,-0.385 0.327,-0.398 0.358,-0.424C0.389,-0.45 0.404,-0.485 0.404,-0.53C0.404,-0.614 0.354,-0.656 0.255,-0.656L0.138,-0.656ZM0.138,-0.341L0.138,-0.044L0.268,-0.044C0.323,-0.044 0.365,-0.057 0.394,-0.083C0.423,-0.11 0.438,-0.147 0.438,-0.196C0.438,-0.292 0.372,-0.341 0.241,-0.341L0.138,-0.341Z" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                                <g transform="matrix(0.402434,-0.915449,0.915449,0.402434,90.1571,914.562)">
                                    <g transform="matrix(7,0,0,7,745.616,388.222)">
                                        <path d="M0.378,0.012C0.279,0.012 0.2,-0.021 0.141,-0.086C0.083,-0.152 0.053,-0.238 0.053,-0.343C0.053,-0.454 0.083,-0.544 0.144,-0.611C0.204,-0.678 0.287,-0.712 0.391,-0.712C0.486,-0.712 0.562,-0.68 0.62,-0.615C0.679,-0.55 0.708,-0.466 0.708,-0.361C0.708,-0.245 0.678,-0.154 0.618,-0.087C0.558,-0.021 0.478,0.012 0.378,0.012ZM0.383,-0.668C0.301,-0.668 0.234,-0.639 0.182,-0.58C0.129,-0.521 0.103,-0.444 0.103,-0.348C0.103,-0.252 0.128,-0.175 0.178,-0.118C0.228,-0.061 0.294,-0.032 0.377,-0.032C0.464,-0.032 0.532,-0.06 0.583,-0.117C0.633,-0.173 0.658,-0.252 0.658,-0.353C0.658,-0.452 0.634,-0.529 0.584,-0.585C0.535,-0.64 0.468,-0.668 0.383,-0.668Z" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                                <g transform="matrix(0.285745,-0.958306,0.958306,0.285745,169.145,989.708)">
                                    <g transform="matrix(7,0,0,7,748.511,381.384)">
                                        <path d="M0.665,-0L0.625,-0L0.469,-0.527C0.461,-0.554 0.457,-0.576 0.456,-0.593L0.454,-0.593C0.453,-0.578 0.448,-0.557 0.439,-0.528L0.273,-0L0.233,-0L0.025,-0.7L0.079,-0.7L0.239,-0.139C0.246,-0.114 0.251,-0.093 0.254,-0.077L0.256,-0.077C0.258,-0.089 0.264,-0.11 0.273,-0.139L0.446,-0.7L0.47,-0.7L0.629,-0.139C0.636,-0.113 0.641,-0.092 0.644,-0.077L0.646,-0.077C0.648,-0.086 0.65,-0.097 0.654,-0.108L0.818,-0.7L0.87,-0.7L0.665,-0Z" style={{fill:color,fillRule:"nonzero"}}/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Box>
    )
}

export default RainbowPicturesLogo;