import React, {useState} from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {Image, ImageWithGalleries} from "../../Common/Types/image";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingArea from "../Components/Common/LoadingArea";
import {useQuery, useQueryClient} from "react-query";
import {imagesKey} from "../../Common/Api/queryKeys";
import {fetchAllImages} from "../../Common/Api/image";
import Alert from "@mui/material/Alert";
import NewPhotoDialog from "../Components/Dialogs/NewPhotoDialog";
import DeletePhotoDialog from "../Components/Dialogs/DeletePhotoDialog";
import NoWatermarkWarningSign from "../Components/Common/NoWatermarkWarningSign";
import EditPhotoDialog from "../Components/Dialogs/EditPhotoDialog";
import EditIcon from '@mui/icons-material/Edit';

interface PhotosListProps {
    handleEditPhoto: (photoId: number) => void,
    handleDeletePhoto: (photoId: number) => void
}

function PhotosList({handleEditPhoto, handleDeletePhoto}: PhotosListProps) {
    const {
        isFetching,
        isError,
        data: photos
    } = useQuery(
        imagesKey(),
        fetchAllImages
    )

    const calculateAspectRatio = (width: number, height: number): string => {
        const gcd = (a: number, b: number): number => {
            return (b === 0) ? a : gcd(b, a % b);
        }

        const commonDenominator = gcd(width, height);
        return `${width / commonDenominator}:${height / commonDenominator}`;
    }

    const getResolutionText = (photo: Image) => {
        return `${photo.imageWidth}x${photo.imageHeight} (${calculateAspectRatio(photo.imageWidth, photo.imageHeight)})`;
    }

    const getNumberOfGalleriesText = (photo: ImageWithGalleries) => {
        switch (photo.imageGalleries.length) {
            case 0:
                return "Nezařazeno"
            case 1:
                return `V ${photo.imageGalleries.length} galerii`;
            default:
                return `V ${photo.imageGalleries.length} galeriích`;
        }
    }

    return (
        <React.Fragment>
            {isFetching && <LoadingArea/>}
            {!isFetching && isError && <Alert severity="error">Chyba při načítání dat</Alert>}
            {!isFetching && !isError && photos &&
                <ImageList cols={3}>
                    {photos.map((photo: ImageWithGalleries) => (
                        <ImageListItem key={photo.id}>
                            <Box sx={{
                                backgroundImage: `url(/api/image/${photo.id}/serve?thumbnail=true)`,
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                height: 250,
                                p: 2,
                                boxSizing: "border-box"
                            }}>
                                {!photo.watermarkImage &&
                                    <NoWatermarkWarningSign/>
                                }
                            </Box>
                            <ImageListItemBar
                                title={<Typography sx={{color: photo.imageGalleries.length === 0 ? "grey" : "white", textShadow: "0 0 3px black"}}>{getNumberOfGalleriesText(photo)}</Typography>}
                                subtitle={<Typography sx={{color: "grey", fontSize: "small", textShadow: "0 0 3px black"}}>{getResolutionText(photo)}</Typography>}
                                actionIcon={
                                    <Stack direction="row">
                                        <Tooltip title="Upravit">
                                            <IconButton onClick={() => handleEditPhoto(photo.id)} aria-label="Upravit" sx={{color: "white"}}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Smazat">
                                            <IconButton onClick={() => handleDeletePhoto(photo.id)} aria-label="Smazat" sx={{color: "white"}}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                }/>
                        </ImageListItem>
                    ))}
                </ImageList>
            }
        </React.Fragment>
    );
}

function PhotosPage() {
    const queryClient = useQueryClient()

    const [activeId, setActiveId] = useState<number | undefined>();

    const [newPhotoDialogOpen, setNewPhotoDialogOpen] = useState<boolean>(false);
    const [editPhotoDialogOpen, setEditPhotoDialogOpen] = useState<boolean>(false);
    const [deletePhotoDialogOpen, setDeletePhotoDialogOpen] = useState<boolean>(false);

    const refreshImages = () => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries(imagesKey())
    }

    const openNewPhotoDialog = () => {
        setNewPhotoDialogOpen(true)
    }

    const closeNewPhotoDialog = (saved: boolean) => {
        setNewPhotoDialogOpen(false)
        if (saved) {
            refreshImages()
        }
    }

    const openEditPhotoDialog = (photoId: number) => {
        setActiveId(photoId)
        setEditPhotoDialogOpen(true)
    }

    const closeEditPhotoDialog = (saved: boolean) => {
        setEditPhotoDialogOpen(false)
        if (saved) {
            refreshImages()
        }
    }

    const openDeletePhotoDialog = (photoId: number) => {
        setActiveId(photoId);
        setDeletePhotoDialogOpen(true);
    }

    const closeDeletePhotoDialog = (deleted: boolean) => {
        setDeletePhotoDialogOpen(false);
        if (deleted) {
            refreshImages()
        }
    }

    return (
        <Container>
            <Button variant="contained" onClick={openNewPhotoDialog}>Nahrát soubor</Button>

            <PhotosList
                handleEditPhoto={openEditPhotoDialog}
                handleDeletePhoto={openDeletePhotoDialog}/>

            {newPhotoDialogOpen && <NewPhotoDialog open={newPhotoDialogOpen} onClose={closeNewPhotoDialog}/>}
            {editPhotoDialogOpen && activeId && <EditPhotoDialog activeId={activeId} open={editPhotoDialogOpen} onClose={closeEditPhotoDialog} />}
            {deletePhotoDialogOpen && activeId && <DeletePhotoDialog activeId={activeId} open={deletePhotoDialogOpen} onClose={closeDeletePhotoDialog}/>}
        </Container>
    );
}

export default PhotosPage;