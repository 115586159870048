import background from "./background-full.jpg";
import backgroundMd from "./background-medium.jpg";
import backgroundSm from "./background-small.jpg";
import {Box, Divider, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import LenkaSignature from "../Common/LenkaSignature";
import {ParallaxBanner, ParallaxBannerLayer} from "react-scroll-parallax";

const imageCoverGradientStyles = {
    width: "100%",
    height: "100%",
    background: "linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 100%)"
}

const siteTitleContainerStyles = {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: "white"
}

const siteTitleStyles = (theme: any) => ({
    [theme.breakpoints.down('md')]: {
        fontSize: "3rem",
        marginBottom: "1rem"
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: "2.5rem"
    },
    fontSize: "4rem",
    fontWeight: 100
})
const signatureStyles = (theme: any) => ({
    [theme.breakpoints.down('md')]: {
        maxWidth: 300
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 260
    },
    mx: "auto",
    mb: -3,
    maxWidth: 450
});

const siteSubtitle2Styles = (theme: any) => ({
    [theme.breakpoints.down('md')]: {
        pl: 25,
        mt: -0.5,
    },
    [theme.breakpoints.down('sm')]: {
        pl: 20,
        mt: -0.5,
    },
    pl: 40,
    mt: -1,
    fontSize: ".8rem",
    fontWeight: 100,
    fontStyle: "italic"
});

const dividerStyles = (theme: any) => ({
    [theme.breakpoints.down('md')]: {
        width: 210,
        my: 1.5
    },
    [theme.breakpoints.down('sm')]: {
        display: "none"
    },
    width: 310,
    borderWidth: 1,
    borderColor: "rgba(201,201,201,.4)",
    margin: "1.5em auto"
});

const scrollArrowContainerStyle = {
    position: "absolute",
    left: "50%",
    bottom: 30,
    transform: "translateX(-50%)",
    color: "white"
}

const scrollArrowStyle = {
    width: 25,
    height: 25,
    borderColor: "white",
    borderWidth: "0 2px 2px 0",
    borderStyle: "solid",
    transform: "rotate(45deg)",
    animation: "pulse 1.5s infinite"
}

interface SplashScreenProps {
    onScrollArrowClicked?: () => void
}

function SplashScreen({onScrollArrowClicked}: SplashScreenProps) {
    const theme = useTheme();
    const ismdDown = useMediaQuery(theme.breakpoints.down("md"));
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <ParallaxBanner style={{width: "100%", height: "100vh"}}>
            <ParallaxBannerLayer image={ismdDown ? backgroundMd : isSmDown ? backgroundSm : background} speed={-15}/>
            <ParallaxBannerLayer>

                <Box sx={imageCoverGradientStyles}>
                    <Box sx={siteTitleContainerStyles}>
                        <Typography variant="h1" sx={siteTitleStyles}>Rainbow Pictures</Typography>
                        <Divider sx={dividerStyles}/>
                        <LenkaSignature color="white" sx={signatureStyles}/>
                        <Typography variant="subtitle2" sx={siteSubtitle2Styles}>— fotografka</Typography>
                    </Box>

                    <Box sx={scrollArrowContainerStyle}>
                        <IconButton size="large" color="inherit" onClick={onScrollArrowClicked}>
                            <Box sx={scrollArrowStyle}/>
                        </IconButton>
                    </Box>
                </Box>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    );
}

export default SplashScreen;