import {GalleryImage} from "../../../../Common/Types/galleryImage";
import {Box, ImageListItem, ImageListItemBar} from "@mui/material";
import React from "react";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NoWatermarkWarningSign from "../../Common/NoWatermarkWarningSign";

interface ActionIconsProps {
    main: boolean,
    numbered: boolean
}

function ActionIcons({main, numbered}: ActionIconsProps) {
    return (
        <Box sx={{p: 1}}>
            {main && <StarBorderIcon sx={{color: "white"}}/>}
            {numbered && <FormatListNumberedIcon sx={{color: "white"}}/>}
        </Box>
    )
}

interface ImageSelectorSelectedGroupImageProps {
    galleryImage: GalleryImage,
    hoverLabel: string,
    onClick: (imageId: number) => void
}

function ImageSelectorSelectedGroupImage({galleryImage, hoverLabel, onClick}: ImageSelectorSelectedGroupImageProps) {
    return (
        <ImageListItem onClick={() => onClick(galleryImage.image.id)}>
            <Box sx={{
                backgroundImage: `url(/api/image/${galleryImage.image.id}/serve?thumbnail=true)`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: 150,
                cursor: "pointer",
                "&:hover *": {
                    display: "flex"
                }
            }}>
                <Box sx={{position: "absolute", p: 1}}>
                    {!galleryImage.image.watermarkImage &&
                        <NoWatermarkWarningSign/>
                    }
                </Box>
                <Box sx={{
                    display: "none",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "white",
                    cursor: "pointer",
                    textShadow: "0px 0px 10px black",
                }}>
                    {hoverLabel}
                </Box>
            </Box>
            {(galleryImage.main || galleryImage.sequence !== null) &&
                <ImageListItemBar
                    position="top"
                    sx={{
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                    }}
                    actionIcon={<ActionIcons main={galleryImage.main} numbered={galleryImage.sequence !== null}/>}
                >
                </ImageListItemBar>
            }
        </ImageListItem>
    )
}

export default ImageSelectorSelectedGroupImage