import {PropsWithChildren} from "react";
import {Box, Container, Typography} from "@mui/material";

const headerStyles = (theme: any) => ({
    [theme.breakpoints.down('sm')]: {
        mx: 3
    },
    mx: 10,
    textAlign: "center",
    py: 7
})

const headerHeadingStyles = {
    fontSize: "1.625rem",
    textTransform: "uppercase",
    mb: 8,
    fontWeight: "bold"
}

const headerContentStyles = {
}

interface EntryHeaderProps extends PropsWithChildren<any> {
    heading: string,
}

function EntryHeader({heading, children}: EntryHeaderProps) {
    return (
        <Container>
            <Box sx={headerStyles}>
                {heading &&
                    <Typography variant="h2" sx={headerHeadingStyles}>{heading}</Typography>
                }
                <Box sx={headerContentStyles}>
                    {children}
                </Box>
            </Box>
        </Container>
    )
}

export default EntryHeader;