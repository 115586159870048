import {Box, Container, Grid, Theme, useMediaQuery, useTheme} from "@mui/material";
import {SxProps} from "@mui/system";
import React, {CSSProperties} from "react";
import RainbowPicturesLogo from "../Common/RainbowPicturesLogo";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {Link} from "react-router-dom";

const pageFooterWrapperStyles: CSSProperties = {
    marginTop: "auto"
}

const pageFooterStyles: SxProps<Theme> = {
    backgroundColor: "rgb(25, 25, 25)",
    color: "silver",
    width: "100%",
    mt: 15,
    pt: 4,
    pb: 5,
    "& a": {
        color: "white"
    }
}

const siteMapStyles: SxProps<Theme> = {
    "& a": {
        display: "block",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline"
        }
    }
}

const contactInfoStyles: SxProps<Theme> = {
    "& address": {
        fontStyle: "normal"
    }
}

const socialButtonsStyles: SxProps<Theme> = {
    textAlign: "center",
    mt: 5,
    "& a:not(:first-of-type)": {
        ml: 1
    }
}

function PageFooter() {
    const theme = useTheme();
    const hideLogo = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <footer style={pageFooterWrapperStyles}>
            <Box sx={pageFooterStyles}>
                <Container>
                    <Grid container spacing={2}>
                        {!hideLogo &&
                            <Grid item md={3} sm={4} sx={{display: "flex", alignItems: "center"}}>
                                <RainbowPicturesLogo size={150} color="silver"/>
                            </Grid>
                        }

                        <Grid item md={4} sm={4} xs={5} sx={siteMapStyles}>
                            <h3>Mapa stránek</h3>
                            <nav>
                                <Link to="/">Úvod</Link>
                                <Link to="/nabidka-foceni">Nabídka focení</Link>
                                <Link to="/cenik">Ceník</Link>
                                <Link to="/o-mne">O mně</Link>
                                <Link to="/podminky">Podmínky</Link>
                                <Link to="/kontakt">Kontakt</Link>
                            </nav>
                        </Grid>

                        <Grid item md={5} sm={4} xs={7} sx={contactInfoStyles}>
                            <h3>Kontakt</h3>
                            <address>
                                Lenka Hlavničková<br/>
                                IČO: 17990092<br />
                                Telefon: <a href="tel:+420608002228">608 002 228</a><br/>
                                Email: <a href="mailto:rainbowpictures@email.cz">rainbowpictures@email.cz</a><br/>
                            </address>
                        </Grid>
                    </Grid>
                </Container>
                <Container sx={socialButtonsStyles}>
                    <a href="https://www.facebook.com/rainbowpictures.cz" target="_blank">
                        <FacebookIcon fontSize="large"/>
                    </a>
                    <a href="https://www.instagram.com/rainbowpictures.cz/" target="_blank">
                        <InstagramIcon fontSize="large"/>
                    </a>
                </Container>
                <Container sx={{textAlign: "center", mt: 2}}>
                    &copy; Rainbow pictures 2023
                </Container>
            </Box>
        </footer>
    )
}

export default PageFooter;