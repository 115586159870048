import {Field, Formik} from "formik";
import {CheckboxWithLabel} from "formik-mui";
import {Button, DialogActions, DialogContent, Stack} from "@mui/material";
import React from "react";


export interface EditPhotoFormValues {
    watermarkImage: boolean
    watermarkThumbnail: boolean
}

interface EditPhotoFormProps {
    initialValues: EditPhotoFormValues,
    onSubmit: (values: EditPhotoFormValues) => void,
    onDialogClose: (saved: boolean) => void
}

function EditPhotoForm({initialValues, onSubmit, onDialogClose}: EditPhotoFormProps) {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({
                  values,
                  handleSubmit,
                  isSubmitting
              }) =>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack direction="column" spacing={2}>
                            <Field
                                name="watermarkImage"
                                component={CheckboxWithLabel}
                                checked={values.watermarkImage}
                                Label={{label: "Vodoznak obrázku", style: {marginLeft: 0}}}
                            />
                            <Field
                                name="watermarkThumbnail"
                                component={CheckboxWithLabel}
                                checked={values.watermarkThumbnail}
                                Label={{label: "Vodoznak miniatury", style: {marginLeft: 0}}}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onDialogClose(false)} disabled={isSubmitting}>Zrušit</Button>
                        <Button type="submit" variant="contained" disabled={isSubmitting}>Uložit</Button>
                    </DialogActions>
                </form>
            }
        </Formik>
    )
}

export default EditPhotoForm;